import { PayloadAction } from '@reduxjs/toolkit';
import { alertStoreKey } from './alert.const';
import {
  createAlert,
  fetchAlert,
  fetchAlerts,
  resolveAlert,
  updateAlert,
} from './alert.thunks';
import { AlertType, SortOrder } from '../../types';
import { createSliceCustom } from '../../utils/tests/createSliceCustom';
import { AlertState, RisksColumns } from './alert.types';
import { DataState } from '../../types/asyncData';

export const alertInitialState: AlertState = {
  alerts: [],
  alertLoading: DataState.Idle,
  alert: null,
  alertsLoading: DataState.Idle,
  resolveAlertLoading: DataState.Idle,
  isNoNavigation: true,
  error: null,
  sortBy: RisksColumns.RESOLVE_BY,
  sortOrder: SortOrder.Ascending,
};

export const returns = createSliceCustom({
  name: alertStoreKey,
  initialState: alertInitialState,
  reducers: {
    setAlertLoadingData(
      state,
      {
        payload,
      }: PayloadAction<{ loading: DataState; isNoNavigation: boolean }>,
    ) {
      state.alertLoading = payload.loading;
      state.isNoNavigation = payload.isNoNavigation ?? false;
    },
    setSortBy: (
      state,
      {
        payload,
      }: PayloadAction<{
        sortBy: RisksColumns.RAISED_ON | RisksColumns.RESOLVE_BY;
        sortOrder: SortOrder;
      }>,
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload?.sortOrder ?? SortOrder.Ascending;

      if (state.alerts === null) return;

      const sortMap: Record<string, (a: AlertType, b: AlertType) => number> = {
        [RisksColumns.RAISED_ON]: (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        [RisksColumns.RESOLVE_BY]: (a, b) =>
          new Date(a.etaForResolve).getTime() -
          new Date(b.etaForResolve).getTime(),
      };

      const sortingFn = sortMap[state.sortBy];

      state.alerts =
        state.sortOrder === SortOrder.Ascending
          ? state.alerts.sort(sortingFn)
          : state.alerts.sort(sortingFn).reverse();
    },
    resetAlert: (state) => {
      state.alert = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createAlert.fulfilled,
      (state, { payload }: PayloadAction<AlertType>) => {
        if (state.alerts === null) return;

        state.alerts.push(payload);
        state.alertLoading = DataState.Fulfilled;
        state.isNoNavigation = false;
      },
    );

    builder
      .addCase(fetchAlert.pending, (state) => {
        state.alertLoading = DataState.Pending;
      })
      .addCase(
        fetchAlert.fulfilled,
        (state, { payload }: PayloadAction<AlertType>) => {
          state.alert = payload;
          state.alertLoading = DataState.Fulfilled;
          state.isNoNavigation = true;
        },
      )
      .addCase(fetchAlert.rejected, (state) => {
        state.alertLoading = DataState.Rejected;
        state.error = new Error('Oops. Something went wrong. :(');
      });

    builder
      .addCase(fetchAlerts.pending, (state) => {
        state.alertsLoading = DataState.Pending;
      })
      .addCase(
        fetchAlerts.fulfilled,
        (state, { payload }: PayloadAction<AlertType[]>) => {
          state.alerts = payload;
          state.alertsLoading = DataState.Fulfilled;
        },
      )
      .addCase(fetchAlerts.rejected, (state) => {
        state.alertsLoading = DataState.Rejected;
        state.error = new Error('Oops. Something went wrong. :(');
      });

    builder
      .addCase(resolveAlert.pending, (state) => {
        state.resolveAlertLoading = DataState.Pending;
      })
      .addCase(
        resolveAlert.fulfilled,
        (state, { payload }: PayloadAction<AlertType>) => {
          if (state.alerts) {
            state.alerts = state.alerts.map((alert) =>
              alert.id === payload.id
                ? {
                    ...alert,
                    resolvedStatus: payload.resolvedStatus,
                    resolvedSummary: payload.resolvedSummary,
                    resolvedAt: payload.resolvedAt,
                  }
                : alert,
            );
          }
          state.resolveAlertLoading = DataState.Fulfilled;
        },
      )
      .addCase(resolveAlert.rejected, (state) => {
        state.resolveAlertLoading = DataState.Rejected;
        state.error = new Error('Oops. Something went wrong. :(');
      });

    builder.addCase(
      updateAlert.fulfilled,
      (state, { payload }: PayloadAction<AlertType>) => {
        state.alert = payload;
      },
    );
  },
});

export const alertSlice = returns.slice;
export const alertReducers = returns.reducers;
export const alertExtraReducers = returns.extraReducers;
