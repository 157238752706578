import React, { CSSProperties } from 'react';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';

interface Props {
  children: JSX.Element;
  style?: CSSProperties;
  size?: 'small' | 'medium' | 'large';
  anchorEl: React.MutableRefObject<HTMLElement | null>;
  handleMouseOver: () => void;
  handleClose: () => void;
  isOpen: boolean;
}

export const AlertPopover: React.FC<Props> = ({
  children,
  style,
  anchorEl,
  handleMouseOver,
  handleClose,
  isOpen,
}) => {
  const id = isOpen ? 'popover-button' : undefined;

  return (
    <Box component="span" style={style}>
      <Popover
        style={{ pointerEvents: 'none' }}
        id={id}
        open={isOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          onMouseEnter: () => handleMouseOver(),
          onMouseLeave: () => handleClose(),
          sx: {
            pointerEvents: 'auto',
            padding: '0.5rem',
            gap: '1.5rem',
          },
        }}
      >
        {children}
      </Popover>
    </Box>
  );
};
