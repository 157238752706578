import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { userDataSelectors } from '../../../redux/userData';
import { UserWithPermissions, AlertType, AccountDetail } from '../../../types';
import { FormikErrors } from 'formik';

interface Props {
  value: UserWithPermissions | null;
  handleChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<AlertType>>;
  error: boolean;
  alertEmailRecipientsEmails: string[];
  isEdit: boolean;
  account: AccountDetail;
}

export const OwnerSelect = ({
  value,
  handleChange,
  error,
  alertEmailRecipientsEmails,
  isEdit,
  account,
}: Props) => {
  const valueArray: UserWithPermissions[] = value ? [value] : [];
  const usersList = useAppSelector(userDataSelectors.getAllUsersList);
  const currentUser = useAppSelector(userDataSelectors.getUserData);

  const handleSelectOwner = useCallback(
    (
      _: React.SyntheticEvent<Element, Event>,
      newValue: UserWithPermissions[],
    ) => {
      const newOwner = newValue?.length
        ? newValue[0].id === value?.id
          ? newValue[1]
          : newValue[0]
        : null;

      handleChange('owner', newOwner);

      if (!isEdit) {
        let updatedalertEmailRecipientsEmails = alertEmailRecipientsEmails;

        if (
          ![
            currentUser?.email,
            account.deliveryManager?.email,
            account.portfolioDeliveryManager?.email,
          ].includes(value?.email)
        ) {
          updatedalertEmailRecipientsEmails = alertEmailRecipientsEmails.filter(
            (email) => email !== value?.email,
          );
        }

        if (newOwner) {
          if (!updatedalertEmailRecipientsEmails.includes(newOwner.email)) {
            updatedalertEmailRecipientsEmails = [
              ...updatedalertEmailRecipientsEmails,
              newOwner.email,
            ];
          }
        }

        handleChange(
          'alertEmailRecipientsEmails',
          updatedalertEmailRecipientsEmails,
        );
      }
    },
    [
      handleChange,
      value?.id,
      alertEmailRecipientsEmails,
      value?.email,
      currentUser?.email,
      isEdit,
      account.deliveryManager,
      account.portfolioDeliveryManager,
    ],
  );

  return (
    <Autocomplete
      options={usersList}
      value={valueArray}
      multiple
      onChange={handleSelectOwner}
      getOptionLabel={(option) => option.fullName}
      clearIcon={null}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Box component="div" width="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-start"
              gap={3}
            >
              <Avatar src={option.avatarUrl} />
              <Typography
                component="p"
                sx={{ fontSize: '14px', color: 'text.primary' }}
              >
                {option.fullName}
              </Typography>
            </Stack>
          </Box>
        </Box>
      )}
      renderTags={(options) =>
        options.map((option) => (
          <Chip
            key={option.id}
            variant="filled"
            avatar={
              <Avatar
                sx={{
                  borderRadius: '50%',
                }}
                variant="circular"
                src={option.avatarUrl ?? undefined}
              >
                {option.fullName}
              </Avatar>
            }
            label={option.fullName}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label="Alert owner"
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
        />
      )}
      sx={{
        '.MuiInputBase-adornedStart': { padding: '9px' },
        '& .MuiChip-root': { borderRadius: 4 },
        marginBottom: 1,
      }}
    />
  );
};
