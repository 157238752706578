import { AlertResolvedStatus, AlertType, SortOrder } from '../../types';
import { DataState } from '../../types/asyncData';
import { alertStoreKey } from './alert.const';

export enum RisksColumns {
  ALERT_DETAILS = 'Alert details',
  REASON = 'Reason',
  RAISED_ON = 'Raised on',
  RESOLVE_BY = 'Resolve by',
}

export interface AlertState {
  alerts: AlertType[] | null;
  alertsLoading: DataState;

  alert: AlertType | null;
  alertLoading: DataState;

  resolveAlertLoading: DataState;

  isNoNavigation: boolean;
  error?: Error | null;
  sortBy: RisksColumns;
  sortOrder: SortOrder;
}

export interface AppStateWithAlertData {
  [alertStoreKey]: AlertState;
}

export interface GetAlertsData {
  resolvedStatus?: AlertResolvedStatus | 'no-filtering';
  accountId: string;
}
