import { configureStore } from '@reduxjs/toolkit';
import { accountSlice, accountStoreKey } from './account';
import { accountsStoreKey, accountsSlice } from './accounts';
import { approvalsStoreKey, approvalsSlice } from './approvals';
import { assessmentKey, assessmentSlice } from './assessment';
import { contractorsStoreKey, contractorsSlice } from './contractors';
import { contractorStoreKey, contractorSlice } from './contractor';
import { kpiDefinitionsStoreKey, kpiDefinitionsSlice } from './kpiDefinitions';
import { kpiMetricsStoreKey, kpiMetricsSlice } from './kpiMetrics';
import { kpiOwnersStoreKey, kpiOwnersSlice } from './kpiOwners';
import { locationsStoreKey, locationsSlice } from './locations';
import { notificationsStoreKey, notificationsSlice } from './notifications';
import { periodsStoreKey, periodsSlice } from './periods';
import { reportsStoreKey, reportsSlice } from './reports';
import { reportIssueStoreKey, reportIssueSlice } from './reportIssue';
import { submissionsSlice, submissionsStoreKey } from './submissions';
import { userDataStoreKey, userDataSlice } from './userData';
import {
  rootDepartmentsStoreKey,
  rootDepatmentsSlice,
} from './rootDepartments';
import { marginReportsSlice, marginReportsStoreKey } from './marginReports';
import { accountsStatusKey, accountsStatusSlice } from './accountsStatus';
import { projectStatusKey, projectStatusSlice } from './projectStatus';
import { statusReportSlice, statusReportStoreKey } from './statusReport';
import { pageTitleSlice, pageTitleStoreKey } from './pageTitle';
import { adminSlice, adminStoreKey } from './admin';
import { clientInvoiceSlice, clientInvoiceStoreKey } from './clientInvoice';
import { timeAndPeopleKey } from './timeAndPeople/timeAndPeople.const';
import { timeAndPeopleSlice } from './timeAndPeople/timeAndPeople.slice';
import { alertStoreKey } from './alert/alert.const';
import { alertSlice } from './alert/alert.slice';

export const store = configureStore({
  reducer: {
    [accountStoreKey]: accountSlice.reducer,
    [accountsStoreKey]: accountsSlice.reducer,
    [accountsStatusKey]: accountsStatusSlice.reducer,
    [approvalsStoreKey]: approvalsSlice.reducer,
    [assessmentKey]: assessmentSlice.reducer,
    [contractorsStoreKey]: contractorsSlice.reducer,
    [contractorStoreKey]: contractorSlice.reducer,
    [kpiDefinitionsStoreKey]: kpiDefinitionsSlice.reducer,
    [kpiMetricsStoreKey]: kpiMetricsSlice.reducer,
    [kpiOwnersStoreKey]: kpiOwnersSlice.reducer,
    [locationsStoreKey]: locationsSlice.reducer,
    [notificationsStoreKey]: notificationsSlice.reducer,
    [periodsStoreKey]: periodsSlice.reducer,
    [reportsStoreKey]: reportsSlice.reducer,
    [statusReportStoreKey]: statusReportSlice.reducer,
    [reportIssueStoreKey]: reportIssueSlice.reducer,
    [clientInvoiceStoreKey]: clientInvoiceSlice.reducer,
    [submissionsStoreKey]: submissionsSlice.reducer,
    [userDataStoreKey]: userDataSlice.reducer,
    [rootDepartmentsStoreKey]: rootDepatmentsSlice.reducer,
    [marginReportsStoreKey]: marginReportsSlice.reducer,
    [projectStatusKey]: projectStatusSlice.reducer,
    [pageTitleStoreKey]: pageTitleSlice.reducer,
    [adminStoreKey]: adminSlice.reducer,
    [timeAndPeopleKey]: timeAndPeopleSlice.reducer,
    [alertStoreKey]: alertSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
