import { createAsyncThunk } from '@reduxjs/toolkit';
import { alertStoreKey } from './alert.const';
import { ResolveAlertType, UpsertAlertType } from '../../types';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { notificationAlert } from '../notifications';
import { formatErrorMessage } from '../../utils';
import { GetAlertsData } from './alert.types';

export const createAlert = createAsyncThunk(
  `${alertStoreKey}/create`,
  async (body: UpsertAlertType, thunkAPI) => {
    try {
      const response = await axios.post(`${apiEndpoints.createAlert()}`, body);
      thunkAPI.dispatch(notificationAlert('Alert created.'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const updateAlert = createAsyncThunk(
  `${alertStoreKey}/update`,
  async (body: UpsertAlertType, thunkAPI) => {
    try {
      const response = await axios.put(`${apiEndpoints.updateAlert()}`, body);
      thunkAPI.dispatch(notificationAlert('Alert saved.'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const resolveAlert = createAsyncThunk(
  `${alertStoreKey}/resolve`,
  async (body: ResolveAlertType, thunkAPI) => {
    try {
      const response = await axios.put(`${apiEndpoints.resolveAlert()}`, body);
      thunkAPI.dispatch(notificationAlert('Alert saved.'));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchAlert = createAsyncThunk(
  `${alertStoreKey}/fetchAlert`,
  async (alertId: string, thunkAPI) => {
    try {
      const response = await axios.get(`${apiEndpoints.getAlert(alertId)}`);
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchAlerts = createAsyncThunk(
  `${alertStoreKey}/fetchAlerts`,
  async ({ resolvedStatus, accountId }: GetAlertsData, thunkAPI) => {
    try {
      const response = await axios.get(`${apiEndpoints.getAlerts()}`, {
        params: {
          resolvedStatus,
          accountId,
        },
      });

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
