import { UserDataState } from './userData.slice';
import { UserWithPermissions, UserPermissions, RockyUser } from '../../types';
import { userDataStoreKey } from './userData.const';

interface AppStateWithUserData {
  [userDataStoreKey]: UserDataState;
}

const selectUserDataState = (state: AppStateWithUserData) => {
  return state[userDataStoreKey];
};

const getUserData = (
  state: AppStateWithUserData,
): UserWithPermissions | null => {
  return selectUserDataState(state).user;
};

const getRockyUserData = (state: AppStateWithUserData): RockyUser | null => {
  return selectUserDataState(state)?.rockyUser ?? null;
};

const getUserPermissions = (
  state: AppStateWithUserData,
): Partial<UserPermissions> => {
  return selectUserDataState(state).user?.permissions || {};
};

const getUserLoading = (state: AppStateWithUserData): boolean => {
  return selectUserDataState(state).loading;
};
const getUnauthorized = (state: AppStateWithUserData): boolean => {
  return selectUserDataState(state).unauthorized;
};

const getUsersList = (
  state: AppStateWithUserData,
): UserWithPermissions[] | null => {
  return selectUserDataState(state).usersList;
};

const getAllUsersList = (
  state: AppStateWithUserData,
): UserWithPermissions[] => {
  return selectUserDataState(state).allUsersList;
};

export const userDataSelectors = {
  getUserData,
  getRockyUserData,
  getUserLoading,
  getUnauthorized,
  getUserPermissions,
  getUsersList,
  getAllUsersList,
};
