const adminKey = '/admin';

export const admin = () => {
  return adminKey;
};

export const adminReports = () => {
  return `${adminKey}/admin-reports`;
};

export const sharedMetricsInconsistencies = () => {
  return `${adminKey}/shared-metrics-inconsistencies`;
};
