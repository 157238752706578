import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Avatar,
  Box,
  Chip,
  Icon,
  Tooltip,
  ListItem,
  useTheme,
  PopoverOrigin,
  Popover,
  Stack,
  styled,
  SxProps,
  Theme,
  TableCellProps,
} from '@mui/material';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import {
  calculateAccountSowStatus,
  calculateAccountStartEndDate,
  getFlagsToolTipMessage,
  sumUpAccountMembers,
  sumUpExpiringAccountMembers,
} from '../../../../redux/accounts';

import { MarginHighlightCell } from '../../../teamMemberView/components/MarginHighlightCell';
import {
  ExpandableTableRowChildProps,
  ExpandableTableRowProps,
} from '../../types';
import {
  AccountStatus,
  AlertType,
  BaseManager,
  CsatValues,
  ProjectOverview,
  SowStatus,
} from '../../../../types';

import {
  NOT_DEFINED_SCORE_VALUE,
  appConfig,
  statusColors,
} from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../constants/paths';
import { scopeTooltipText, timelineTooltipText } from '../../Budgets.utils';
import { isDateInCurrentWeek } from '../../../globalTracker/utils';

import { formatStringDate, getDateDifference, is } from '../../../../utils';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { blue } from '@mui/material/colors';
import { uuid4 } from '@sentry/utils';
import { AccountMenuDropDown } from '../AccountMenuDropDown/AccountMenuDropDown';
import { ReactComponent as RedFlagIcon } from '../../../../assets/redFlagIcon.svg';
import { ReactComponent as YellowFlagIcon } from '../../../../assets/yellowFlagIcon.svg';
import { priceFormat } from '../../../../components/tableCellRenders';

import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { COLORS } from '../../../../utils/colors';

const AccountRow = ({
  onClick,
  account,
  isCLevelUser,
}: ExpandableTableRowProps) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleSetOpenCard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      setOpen(!open);
    },
    [setOpen, open],
  );

  const navigateToFlagsUrl = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      departmenId: string | undefined,
    ) => {
      event?.stopPropagation();
      if (is.dev || is.localhost) {
        window.open(
          `${appConfig.rockyDevUrl}/all-flags?status=in_progress&projects=${departmenId}`,
          '_blank',
          'noopener,noreferrer',
        );
      } else if (is.stage || is.preprod) {
        window.open(
          `${appConfig.rockyStageUrl}/all-flags?status=in_progress&projects=${departmenId}`,
          '_blank',
          'noopener,noreferrer',
        );
      } else {
        window.open(
          `${appConfig.rockyProdUrl}/all-flags?status=in_progress&projects=${departmenId}`,
          '_blank',
          'noopener,noreferrer',
        );
      }
    },
    [],
  );

  const accountSows = useMemo(() => {
    return calculateAccountSowStatus(account);
  }, [account]);

  const { accountStartDate, accountEndDate, endingWithinDays } = useMemo(() => {
    return calculateAccountStartEndDate(account);
  }, [account]);

  const expiringMembersCount = useMemo(() => {
    return sumUpExpiringAccountMembers(account);
  }, [account]);

  const flagsLength = useMemo(() => {
    return account.flagsCount.red + account.flagsCount.yellow;
  }, [account]);

  const onClickAssessment = useCallback(
    ({
      accountId,
      projectId,
      event,
    }: {
      accountId: string;
      projectId?: string;
      event: Partial<Event>;
    }) => {
      !!event.stopPropagation && event.stopPropagation();

      let assessmentUrl = `${accountId}/${paths.projectStatus}`;

      if (projectId) {
        assessmentUrl += `&project=${projectId}`;
      }

      navigate(assessmentUrl);
    },
    [navigate],
  );

  const onClickAlert = useCallback(
    ({ accountId, event }: { accountId: string; event: Partial<Event> }) => {
      !!event.stopPropagation && event.stopPropagation();
      navigate(`${accountId}/${paths.risks}`);
    },
    [navigate],
  );

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
          background: (theme) =>
            open
              ? theme.palette.background.default
              : account.alert?.createdAt
              ? COLORS.HIGHLIGHTS.CRITICAL
              : '',
          transition: 'background 0.25s',
          '&:hover': {
            background: (theme) => theme.palette.action.hover,
          },
        }}
        onClick={onClick}
      >
        {/* Account Name */}
        <CustomTableCell sx={{ paddingX: 2 }}>
          <Box display={'flex'} alignItems={'center'} pl={1.25}>
            {account.status === AccountStatus.Closed ? (
              <Tooltip title="Closed" color="disabled" placement="top">
                <WorkOffOutlinedIcon />
              </Tooltip>
            ) : (
              <GeneralScore
                accountId={account.id}
                onClickAssessment={onClickAssessment}
                score={account.latestGeneralAssessmentScore}
                alert={account.alert}
                onClickAlert={onClickAlert}
              />
            )}

            <ListItem>
              <Typography
                whiteSpace="nowrap"
                variant={open ? 'h4' : 'h5'}
                color={
                  account.status === AccountStatus.Closed
                    ? '#00000099'
                    : undefined
                }
              >
                {account.name}
              </Typography>

              <IconButton
                aria-label="Expand/collapse icon"
                size="small"
                onClick={handleSetOpenCard}
              >
                {open ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}
              </IconButton>

              <Typography whiteSpace="nowrap" variant="body2">
                {account.projects.length}
              </Typography>
            </ListItem>

            <TimelineScore
              accountId={account.id}
              onClickAssessment={onClickAssessment}
              score={account.latestTimelineAssessmentScore}
            />

            <ScopeScore
              accountId={account.id}
              onClickAssessment={onClickAssessment}
              score={account.latestScopeAssessmentScore}
            />
          </Box>
        </CustomTableCell>

        {/* Account Margin */}
        <MarginHighlightCell
          align="right"
          marginHighlights={account.marginHighlights || []}
          value={account.grossMargin}
          sx={{ paddingRight: '24px' }}
        >
          {account.grossMargin == null ? 'NA' : `${account.grossMargin}%`}
        </MarginHighlightCell>

        {/* Account Revenue */}
        <CustomTableCell>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="end"
            justifyContent="end"
          >
            <Typography
              align={'right'}
              sx={{ fontWeight: '500', paddingRight: '8px' }}
            >
              {priceFormat({
                value: account.revenue
                  ? Math.round(account.revenue)
                  : account.revenue,
              })}
            </Typography>
          </Box>
        </CustomTableCell>

        {/* Account Headcount */}
        <CustomTableCell>
          <Box display="flex" flexDirection="row">
            <Typography whiteSpace="nowrap" variant="body1">
              {sumUpAccountMembers(account)}
            </Typography>
          </Box>
        </CustomTableCell>

        {/* Account Positions */}
        <CustomTableCell>
          <Box display="flex" flexDirection="row">
            <Typography whiteSpace="nowrap" variant="body1">
              {(account.openPositions ?? 0) !== 0
                ? `+${account.openPositions ?? 0}`
                : '—'}
            </Typography>
          </Box>
        </CustomTableCell>

        {/* Account SOW Status */}
        <CustomTableCell>
          <Box textAlign="left" paddingRight={'16px'}>
            <Typography whiteSpace="nowrap" variant="body1">
              {accountSows.potential === account.projects.length
                ? SowStatus.Potential
                : accountSows.expired
                ? SowStatus.Expired
                : SowStatus.Active}
            </Typography>
            <Typography whiteSpace="nowrap" variant="body2">
              {!!accountSows.potential &&
                `${accountSows.potential} ${SowStatus.Potential} projects`}
            </Typography>
            {!!expiringMembersCount && (
              <Typography whiteSpace="nowrap" variant="body1" color={'red'}>
                {`${expiringMembersCount} expiring within 30 days`}
              </Typography>
            )}
          </Box>
        </CustomTableCell>

        {/* Account Forcasted End date */}
        <ForecastedEndDate
          endedAt={accountEndDate}
          startedAt={accountStartDate}
          endingWithinDays={endingWithinDays}
        />

        {/* Account PDM */}
        {isCLevelUser && (
          <AssignedUser baseManager={account.portfolioDeliveryManager} />
        )}

        {/* Account DM */}
        <AssignedUser baseManager={account.deliveryManager} />

        {/* Flags Count */}
        <CustomTableCell customProps={{ align: 'left' }}>
          {flagsLength > 0 ? (
            <Tooltip
              title={getFlagsToolTipMessage(account.flagsCount) ?? ''}
              placement="top"
            >
              <Box
                textAlign="center"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                onClick={(e) => navigateToFlagsUrl(e, account.zohoId)}
              >
                <Typography whiteSpace="nowrap" variant="body1">
                  {flagsLength}
                </Typography>
                <span>
                  {account.flagsCount.red === 0 ? (
                    <YellowFlagIcon />
                  ) : (
                    <RedFlagIcon />
                  )}
                </span>
              </Box>
            </Tooltip>
          ) : (
            <Box
              sx={{ color: 'text.disabled', mr: 0.5 }}
              onClick={(e) => navigateToFlagsUrl(e, account.zohoId)}
            >
              &mdash;
            </Box>
          )}
        </CustomTableCell>

        {/* Account CSAT */}
        <CustomTableCell customProps={{ align: 'center' }}>
          <CsatChip csat={account.csat} />
        </CustomTableCell>

        {/* DropDown Menu */}
        <CustomTableCell customProps={{ align: 'center' }}>
          <AccountMenuDropDown
            accountName={account.name}
            sharedDriveLink={account.sharedDriveLink}
            sowLink={account.sowLink}
            customLinks={account.customLinks}
            compensationToolLink={account.compensationToolLink}
            accountId={account.id}
          />
        </CustomTableCell>
      </TableRow>

      <ProjectRows
        open={open}
        account={account}
        onClickAssessment={onClickAssessment}
      />
    </>
  );
};

interface CustomTableCellProps {
  sx?: SxProps<Theme> | undefined;
  customProps?: TableCellProps;
}

const CustomTableCell: React.FC<PropsWithChildren<CustomTableCellProps>> = ({
  children,
  sx,
  customProps,
}) => {
  return (
    <TableCell
      sx={{
        paddingX: 0,
        paddingY: 1,
        ...sx,
      }}
      {...customProps}
    >
      {children}
    </TableCell>
  );
};

const StyledBudgetTableRow = styled(TableRow)`
  cursor: pointer;
  transition: background 0.25s;
  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`;

const ProjectRows = ({
  account,
  open,
  onClickAssessment,
}: ExpandableTableRowChildProps) => {
  const navigate = useNavigate();

  const onSelectProject = useCallback(
    (project: ProjectOverview) => {
      navigate(`${paths.portfolio}/${account.id}/${project.id}`);
    },
    [navigate, account.id],
  );

  const onAddAssessment = useCallback(
    (accountId: string, projectId: string) => {
      navigate(`${accountId}/${projectId}/${paths.projectStatus}`);
    },
    [navigate],
  );

  if (!open) return <></>;

  return (
    <>
      {account.projects.map((project) => {
        const showAddCommentIcon =
          !project.latestGeneralAssessment ||
          !isDateInCurrentWeek(
            new Date(project.latestGeneralAssessment.forDate),
          );

        const endingWithinDays =
          project.endedAt === null
            ? undefined
            : getDateDifference(new Date(), new Date(project.endedAt));

        return (
          <StyledBudgetTableRow
            key={project.id}
            onClick={() => onSelectProject(project)}
          >
            {/* Project Name */}
            <TableCell>
              <Box display="flex" alignItems="center" ml={3.5}>
                <GeneralScore
                  projectId={project.id}
                  accountId={account.id}
                  onClickAssessment={onClickAssessment}
                  score={project.latestGeneralAssessment?.score}
                />

                <ListItem>
                  <Typography whiteSpace="nowrap" variant={open ? 'h4' : 'h5'}>
                    {project.name}
                  </Typography>
                </ListItem>

                {showAddCommentIcon && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ minWidth: '20px' }}
                    justifyContent="flex-start"
                  >
                    <Tooltip title="Add assessment" placement="top">
                      <AddCommentIcon
                        sx={{
                          fontSize: '1rem',
                          color: blue[500],
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onAddAssessment(account.id, project.id);
                        }}
                      />
                    </Tooltip>
                  </Stack>
                )}

                <TimelineScore
                  accountId={account.id}
                  onClickAssessment={onClickAssessment}
                  score={project.latestTimelineAssessment?.score}
                />

                <ScopeScore
                  accountId={account.id}
                  onClickAssessment={onClickAssessment}
                  score={project.latestScopeAssessment?.score}
                />
              </Box>
            </TableCell>

            {/* Project Margin */}
            <MarginHighlightCell
              align="center"
              marginHighlights={account.marginHighlights || []}
              value={account.grossMargin}
            >
              {project.grossMargin == null ? 'NA' : `${project.grossMargin}%`}
            </MarginHighlightCell>

            {/* Project Revenue */}
            <CustomTableCell>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="end"
                justifyContent="end"
              >
                <Typography align={'right'} sx={{ fontWeight: '500' }}>
                  {priceFormat({
                    value: project.revenue
                      ? Math.round(project.revenue)
                      : project.revenue,
                  })}
                </Typography>
              </Box>
            </CustomTableCell>

            {/* Project Size */}
            <TableCell align="right">
              <Box
                textAlign="right"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography whiteSpace="nowrap" variant="body1">
                  {project.membersCount}
                </Typography>
                <IconButton aria-label="Flag icon" size="small">
                  <Icon />
                </IconButton>
              </Box>
            </TableCell>

            {/* Project SOW Status */}
            <TableCell>
              <Typography whiteSpace="nowrap" variant="body1" pl={0.5}>
                {project.sowStatus}
              </Typography>
              {!!project.membersWithSowToExpireWithinMonth && (
                <Typography
                  whiteSpace="nowrap"
                  variant="body1"
                  pl={0.5}
                  color={'red'}
                >
                  {`${project.membersWithSowToExpireWithinMonth} expiring within 30 days`}
                </Typography>
              )}
            </TableCell>

            {/* Project Forcasted End date */}
            <ForecastedEndDate
              endedAt={
                project.endedAt &&
                new Date(project.endedAt)?.toLocaleDateString('en-US')
              }
              startedAt={
                project.startedAt &&
                new Date(project.startedAt)?.toLocaleDateString('en-US')
              }
              endingWithinDays={endingWithinDays}
            />
            <TableCell colSpan={5}></TableCell>
          </StyledBudgetTableRow>
        );
      })}
    </>
  );
};

interface ForecastedEndDateProps {
  startedAt: string | null;
  endedAt: string | null;
  endingWithinDays: number | undefined;
}

const ForecastedEndDate: React.FC<ForecastedEndDateProps> = ({
  startedAt,
  endedAt,
  endingWithinDays,
}) => {
  return (
    <CustomTableCell>
      <Box component="span" sx={{ color: 'text.disabled' }}>
        {!!!endedAt && !!!startedAt ? (
          <Typography whiteSpace="nowrap" variant="button">
            &mdash;
          </Typography>
        ) : (
          <>
            {!!startedAt && !!!endedAt ? (
              <Typography whiteSpace="nowrap" variant="button">
                &infin;
              </Typography>
            ) : (
              <>
                {!!endedAt && (
                  <Typography
                    whiteSpace="nowrap"
                    variant="body1"
                    color={
                      endingWithinDays
                        ? endingWithinDays > 30
                          ? 'black'
                          : 'red'
                        : 'black'
                    }
                  >
                    {endedAt}
                  </Typography>
                )}
                {!!startedAt && (
                  <Typography whiteSpace="nowrap" variant="body2">
                    {`Started ${startedAt}`}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </CustomTableCell>
  );
};

interface ScoreProps {
  score?: number;
  onClickAssessment: (params: {
    accountId: string;
    projectId?: string | undefined;
    event: Partial<Event>;
  }) => void;
  accountId: string;
  projectId?: string;
  alert?: AlertType;
  onClickAlert?: ({
    accountId,
    event,
  }: {
    accountId: string;
    event: Partial<Event>;
  }) => void;
}

const GeneralScore: React.FC<ScoreProps> = ({
  score,
  onClickAssessment,
  accountId,
  projectId,
  alert,
  onClickAlert,
}) => {
  if (alert && onClickAlert) {
    return (
      <Tooltip
        title={`Alert since ${formatStringDate(alert.createdAt, 'MMM d')}`}
        placement="top"
      >
        <NotificationsActiveIcon
          sx={{ fontSize: '1.2rem', color: statusColors[0] }}
          onClick={(e) => onClickAlert({ event: e, accountId })}
        />
      </Tooltip>
    );
  }
  switch (score) {
    case 0:
      return (
        <Tooltip title="General: red" placement="top">
          <ErrorRoundedIcon
            sx={{ fontSize: '1.2rem', color: statusColors[0] }}
            onClick={(e) =>
              onClickAssessment({ event: e, accountId, projectId })
            }
          />
        </Tooltip>
      );
    case 1:
      return (
        <Tooltip title="General: yellow" placement="top">
          <WarningRoundedIcon
            sx={{
              fontSize: '1.2rem',
              color: statusColors[score],
            }}
            onClick={(e) =>
              onClickAssessment({ event: e, accountId, projectId })
            }
          />
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip title="General: green" placement="top">
          <CheckCircleRoundedIcon
            sx={{
              fontSize: '1.2rem',
              color: statusColors[score],
            }}
            onClick={(e) =>
              onClickAssessment({ event: e, accountId, projectId })
            }
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="No status provided" placement="top">
          <FiberManualRecordIcon
            sx={{
              fontSize: '1.2rem',
              color: statusColors[NOT_DEFINED_SCORE_VALUE],
            }}
            onClick={(e) =>
              onClickAssessment({ event: e, accountId, projectId })
            }
          />
        </Tooltip>
      );
  }
};

const TimelineScore: React.FC<ScoreProps> = ({
  onClickAssessment,
  score,
  accountId,
  projectId,
}: ScoreProps) => {
  return (
    <Tooltip title={timelineTooltipText(score)} placement="top">
      <FiberManualRecordIcon
        sx={{
          fontSize: '0.7rem',
          color: statusColors[score ?? NOT_DEFINED_SCORE_VALUE],
        }}
        onClick={(e) =>
          onClickAssessment({
            event: e,
            accountId,
            projectId,
          })
        }
      />
    </Tooltip>
  );
};

const ScopeScore: React.FC<ScoreProps> = ({
  onClickAssessment,
  score,
  accountId,
  projectId,
}: ScoreProps) => {
  return (
    <Tooltip title={scopeTooltipText(score)} placement="top">
      <FiberManualRecordIcon
        sx={{
          fontSize: '0.7rem',
          color: statusColors[score ?? NOT_DEFINED_SCORE_VALUE],
        }}
        onClick={(e) =>
          onClickAssessment({
            event: e,
            accountId,
            projectId,
          })
        }
      />
    </Tooltip>
  );
};

interface AssignedUserProps {
  baseManager?: BaseManager;
}

const AssignedUser: React.FC<AssignedUserProps> = ({
  baseManager,
}: AssignedUserProps) => {
  return (
    <CustomTableCell customProps={{ align: 'left' }}>
      {baseManager ? (
        <Typography whiteSpace="nowrap" variant="body1">
          {baseManager?.fullName.split(' ').map((n) => (
            <React.Fragment key={n + uuid4()}>
              <span>{n}</span>
              <br />
            </React.Fragment>
          ))}
        </Typography>
      ) : (
        <Chip
          sx={{ border: 0, borderRadius: 0, maxWidth: 'none' }}
          avatar={<Avatar src={''} sx={{ borderRadius: 4, bgcolor: 'gray' }} />}
          label={
            <Typography whiteSpace="nowrap" variant="body1">
              Not Assigned
            </Typography>
          }
          variant="outlined"
        />
      )}
    </CustomTableCell>
  );
};

interface CsatChipProps {
  csat?: CsatValues;
}

const CsatChip: React.FC<CsatChipProps> = ({ csat }) => {
  const csatAnchor = React.useRef<HTMLElement | null>(null);
  const { palette } = useTheme();
  const [openCsatPopover, setOpenCsatPopover] = useState(false);

  const handleCsatPopoverOpen = useCallback(() => {
    setOpenCsatPopover(true);
  }, []);

  const handleCsatPopoverClose = useCallback(() => {
    setOpenCsatPopover(false);
  }, []);

  const getBackgroundColorByScore = useCallback(
    (score: number): string => {
      score = +score.toFixed(0);
      return palette.scale10(score);
    },
    [palette],
  );

  return (
    <>
      {!!(csat && Object.values(csat).some((c) => !!c)) ? (
        <Box ref={csatAnchor}>
          <Chip
            label={<Typography variant="h3">{csat.latest}</Typography>}
            sx={{
              backgroundColor: getBackgroundColorByScore(csat.latest ?? 0),
              width: '3rem',
              height: '3rem',
              color: csat.latest ? 'white' : 'text.disabled',
            }}
            onMouseEnter={handleCsatPopoverOpen}
            onMouseLeave={handleCsatPopoverClose}
          />
          <CsatPopover
            anchorEl={csatAnchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openCsatPopover}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 5,
            }}
            handlePopoverOpen={handleCsatPopoverOpen}
            handlePopoverClose={handleCsatPopoverClose}
            csat={csat}
          />
        </Box>
      ) : (
        <Tooltip title="No reviews with score">
          <Typography
            whiteSpace="nowrap"
            variant="h3"
            pl={0.5}
            sx={{ color: 'text.disabled' }}
          >
            &mdash;
          </Typography>
        </Tooltip>
      )}
    </>
  );
};

interface CsatPopoverProps {
  anchorEl: React.MutableRefObject<HTMLElement | null>;
  anchorOrigin: PopoverOrigin;
  open: boolean;
  transformOrigin: PopoverOrigin;
  handlePopoverOpen: () => void;
  handlePopoverClose: () => void;
  csat: CsatValues;
}

const CsatPopover: React.FC<CsatPopoverProps> = ({
  anchorEl,
  anchorOrigin,
  open,
  transformOrigin,
  handlePopoverOpen,
  handlePopoverClose,
  csat,
}) => {
  const onMouseEnter = useCallback(() => {
    handlePopoverOpen();
  }, [handlePopoverOpen]);

  const onMouseLeave = useCallback(() => {
    handlePopoverClose();
  }, [handlePopoverClose]);

  return (
    <Popover
      style={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl.current}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave,
        sx: {
          pointerEvents: 'auto',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0.5rem',
          gap: '1.5rem',
        },
      }}
    >
      <Box
        display="flex"
        padding="0 0.5rem"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Typography display="inline" variant="h4">
          Average CSATs
        </Typography>
        <Typography display="inline" variant="body1">
          All time: {csat.all ?? 'NA'}
        </Typography>
        <Typography display="inline" variant="body1">
          Last 6 months: {csat.last6Months ?? 'NA'}
        </Typography>
        <Typography display="inline" variant="body1">
          Last 3 months: {csat.last3Months ?? 'NA'}
        </Typography>
      </Box>
    </Popover>
  );
};

export default AccountRow;
