import * as Sentry from '@sentry/react';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  RockyUser,
  TermsOfUserAcceptResp,
  UserAccountsCountDto,
  UserWithPermissions,
} from '../../types';
import { userDataStoreKey } from './userData.const';
import {
  acceptTermsOfUse,
  fetchAllUsersList,
  fetchRockyUserData,
  fetchUserAccountsCounts,
  fetchUserData,
  fetchUsersList,
} from './userData.thunks';
import { DefaultServerResponse } from '../../types/server-response';
import { UserPrivilegedGroupNames } from '../../constants';
import { createSliceCustom } from '../../utils/tests/createSliceCustom';

export interface UserDataState {
  usersList: UserWithPermissions[] | null;
  allUsersList: UserWithPermissions[];
  user: UserWithPermissions | null;
  rockyUser: RockyUser | null;
  loading: boolean;
  unauthorized: boolean;
  bonusPlan: any | null;
}

export const initialState: UserDataState = {
  user: null,
  rockyUser: null,
  loading: false,
  unauthorized: false,
  usersList: null,
  bonusPlan: null,
  allUsersList: [],
};

export const returns = createSliceCustom({
  name: userDataStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchUserData.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<DefaultServerResponse<UserWithPermissions>>,
        ) => {
          state.loading = false;

          if (payload.data) {
            Sentry.setUser({
              username: payload.data?.fullName,
              email: payload.data?.email,
            });
          }

          state.user = {
            ...payload.data,
            permissions: {
              ...payload.data.permissions,
              canViewMyMetrics: true,
            },
          };
          state.unauthorized = false;
        },
      )
      .addCase(fetchUserData.rejected, (state) => {
        state.loading = false;
        state.unauthorized = true;
      });

    // users list
    builder
      .addCase(
        fetchUsersList.fulfilled,
        (state, { payload }: PayloadAction<UserWithPermissions[]>) => {
          state.usersList = payload || [];
        },
      )
      .addCase(fetchUsersList.rejected, (state) => {
        state.usersList = [];
      });

    // all users list
    builder
      .addCase(
        fetchAllUsersList.fulfilled,
        (state, { payload }: PayloadAction<UserWithPermissions[]>) => {
          state.allUsersList = payload || [];
        },
      )
      .addCase(fetchAllUsersList.rejected, (state) => {
        state.allUsersList = [];
      });

    // users accounts count
    builder.addCase(
      fetchUserAccountsCounts.fulfilled,
      (state, { payload }: PayloadAction<UserAccountsCountDto>) => {
        if (state.user) {
          state.user.permissions.hasAccounts = payload.accountsCount > 0;
          state.user.permissions.isCompensationManager = payload.roles.includes(
            UserPrivilegedGroupNames.CompensationManager,
          );
          state.user.permissions.isBillRateManager = payload.roles.includes(
            UserPrivilegedGroupNames.BillRateManager,
          );
          state.user.permissions.isFinanceManager = payload.roles.includes(
            UserPrivilegedGroupNames.FinanceManagerGlobal,
          );
          state.user.permissions.isFinanceAdmin = payload.roles.includes(
            UserPrivilegedGroupNames.FinanceAdmin,
          );
          state.user.permissions.isFinance =
            state.user.permissions.isFinanceManager ||
            state.user.permissions.isFinanceAdmin;

          state.user.permissions.seesAllAccounts = [
            'alex.gorlatov@agileengine.com',
            'alex.kalinovsky@agileengine.com',
          ].includes(state.user.email);
        }
      },
    );

    // rockyUser
    builder.addCase(
      fetchRockyUserData.fulfilled,
      (state, { payload }: PayloadAction<RockyUser>) => {
        state.rockyUser = payload;
      },
    );

    // rockyUser
    builder.addCase(
      acceptTermsOfUse.fulfilled,
      (state, { payload }: PayloadAction<TermsOfUserAcceptResp>) => {
        if (state.rockyUser) {
          state.rockyUser.latestTermsOfUseVersionAccepted = payload.version;
        }
      },
    );
  },
});

export const userDataSlice = returns.slice;
export const userDataReducers = returns.reducers;
export const userDataExtraReducers = returns.extraReducers;
