import { alertStoreKey } from './alert.const';
import { AppStateWithAlertData as State } from './alert.types';

const select = (state: State) => state[alertStoreKey];

const getAlert = (state: State) => select(state)?.alert;
const getAlertLoading = (state: State) => select(state)?.alertLoading;
const getAlerts = (state: State) => select(state)?.alerts;
const getAlertsLoading = (state: State) => select(state)?.alertsLoading;
const getAlertIsNoNavigation = (state: State) => select(state)?.isNoNavigation;
const getSortBy = (state: State) => select(state).sortBy;
const getSortOrder = (state: State) => select(state).sortOrder;

export const alertDataSelector = {
  getAlert,
  getAlertLoading,
  getAlerts,
  getAlertsLoading,
  getAlertIsNoNavigation,
  getSortBy,
  getSortOrder,
};
