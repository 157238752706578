import formatDate from 'date-fns/format';
import { DateTime } from 'luxon';

export function createDateFromYYYYMMFormat(date?: null): null;
export function createDateFromYYYYMMFormat(date: number | string): Date;
export function createDateFromYYYYMMFormat(
  date?: number | string | null,
): Date | null;
export function createDateFromYYYYMMFormat(
  date: number | string | null | undefined,
): Date | null {
  if (date) {
    const dateString = String(date);
    const year = Number(dateString.slice(0, 4));
    const month = Number(dateString.slice(4));
    return new Date(year, month - 1);
  }
  return null;
}

export function yyyyMMToQuarterName(
  period: number | string,
  shiftMonths?: boolean,
): string {
  const month = Number(String(period).slice(4)) - (shiftMonths ? 1 : 0);
  const year = Number(String(period).slice(1, 4));

  // https://agileengine.atlassian.net/browse/FK-381
  if (month === 0) {
    return `Q4 ’${year - 1}`;
  }

  return `Q${Math.ceil(month / 3)} ’${year}`;
}

export const formatDateToYYYYMM = (date: Date | null): number | null => {
  if (!date || date?.toString() === 'Invalid Date') {
    return null;
  }
  return Number(formatDate(date, 'yyyyMM'));
};

export const formatDateToMMMyyyy = (date: Date | null): string | null => {
  if (!date || date?.toString() === 'Invalid Date') {
    return null;
  }
  return formatDate(date, 'MMM yyyy');
};

export const formatDateTo_yyyy_MM_dd = (date: Date | null): string | null => {
  if (!date || date?.toString() === 'Invalid Date') {
    return null;
  }
  return formatDate(date, 'yyyy-MM-dd');
};

export const formatDateTo_MMM_dd_yyyy = (date: Date | null): string | null => {
  if (!date || date?.toString() === 'Invalid Date') {
    return null;
  }
  return formatDate(date, 'MMM dd, yyyy');
};

export const formatYYYYMMDateToMMMMyyyyString = (
  date: number | string | null | undefined,
  shortMonth = false,
): string => {
  if (date) {
    return formatDate(
      createDateFromYYYYMMFormat(date) as Date,
      `${shortMonth ? 'MMM' : 'MMMM'} yyyy`,
    );
  }
  return '';
};

export const formatYYYYMMDateToMMMMYString = (
  date: number | string | null | undefined,
  shortMonth = false,
): string => {
  if (date) {
    return formatDate(
      createDateFromYYYYMMFormat(date) as Date,
      `${shortMonth ? 'MMM' : 'MMMM'} Y`,
    );
  }
  return '';
};

export const formatYYYYMMDateToMMMMyyString = (
  date: number | string | null | undefined,
  shortMonth = false,
): string => {
  if (date) {
    return formatDate(
      createDateFromYYYYMMFormat(date) as Date,
      `${shortMonth ? 'MMM' : 'MMMM'} yy`,
    );
  }
  return '';
};

export const formatYYYYMMDateToMMMMString = (
  date: number | string | null | undefined,
  shortMonth = false,
): string => {
  if (date) {
    return formatDate(
      createDateFromYYYYMMFormat(date) as Date,
      shortMonth ? 'MMM' : 'MMMM',
    );
  }
  return '';
};

export const formatYYYYMMDateToYString = (
  date: number | string | null | undefined,
): string => {
  if (date) {
    return formatDate(createDateFromYYYYMMFormat(date) as Date, 'Y');
  }
  return '';
};

export const formatYYYYMM = (
  dateNumber: number | null,
  format = 'MMM yyyy',
) => {
  if (!dateNumber) {
    return '';
  }

  const date = createDateFromYYYYMMFormat(dateNumber);

  return formatDate(date, format);
};

export const formatStringDate = (
  dateString: string | null,
  format = 'dd MMM yyyy',
) => {
  if (!dateString) {
    return '';
  }

  return formatDate(new Date(dateString), format);
};

export const getTimezoneOffset = () => {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset();
  const offsetHours = (offsetMinutes / 60) * -1;

  return offsetHours;
};

export const getDateDifference = (date1: Date, date2: Date) => {
  const dateDiff = Math.abs(date2.getTime() - date1.getTime());
  const dateDiffInDays = Math.ceil(dateDiff / (1000 * 60 * 60 * 24));

  return dateDiffInDays;
};

export const formatStringToDate = (dateString: string | null): Date | null => {
  if (!dateString) return null;
  return new Date(dateString);
};

export const dateToIsoString = (date: Date | null) => {
  if (!date || date?.toString() === 'Invalid Date') return null;
  return date.toISOString();
};

export const millisecondsToDays = (milliseconds: number) => {
  return Math.ceil(milliseconds / 1000 / 60 / 60 / 24);
};

export const currDayWithOptionalYear = (date: string) => {
  const dateTime = DateTime.fromISO(date);

  let dayMonth = dateTime.toFormat('d LLL');
  if (dateTime.year !== DateTime.now().year) {
    dayMonth += ` ${dateTime.year}`;
  }

  return dayMonth;
};

export const addDaysToDate = (date: Date, noOfDays: number) => {
  return new Date(date.setDate(date.getDate() + noOfDays));
};
