import {
  Autocomplete,
  FilterOptionsState,
  InputAdornment,
  ListItem,
  TextField,
} from '@mui/material';

import React, { useCallback, useMemo } from 'react';

import {
  PlainObject,
  UserForSearch,
  UserWithPermissions,
} from '../../../types';

import { useMapUsersForSearch } from '../../../components/usersSearchBox/UsersSearchBox.utils';
import { cn } from '../../../utils';
import { SearchListBoxComponent } from '../../../components/usersSearchBox/components/SearchListboxComponent';
import Fuse from 'fuse.js';
import { MemberInfoCell } from '../../../components/tableCellRenders';
import SearchIcon from '@mui/icons-material/SearchRounded';

interface Props {
  options: UserWithPermissions[];
  handleChange: (
    _: React.SyntheticEvent<Element, Event>,
    newValue: UserForSearch | null,
  ) => void;
}

export const EmailRecipientSearch = ({ options, handleChange }: Props) => {
  const searchOptions = useMapUsersForSearch(options);
  const fuse = useMemo(
    () =>
      new Fuse(searchOptions || [], {
        threshold: 0.35,
        keys: ['names', 'fullName'],
      }),
    [searchOptions],
  );

  const handleFilterOptions = useCallback(
    (
      searchOptions: UserForSearch[],
      state: FilterOptionsState<UserForSearch>,
    ) => {
      return fuse.search(state.inputValue).map(({ item }) => item);
    },
    [fuse],
  );

  return (
    <Autocomplete<UserForSearch>
      loading={options === null}
      size="small"
      blurOnSelect
      onChange={handleChange}
      options={searchOptions || []}
      getOptionLabel={(opt) => opt.fullName}
      ListboxComponent={SearchListBoxComponent}
      className={cn('MuiAutocomplete-searchBox')}
      componentsProps={{
        popper: {
          style: { width: '300px' },
        },
      }}
      filterOptions={handleFilterOptions}
      renderOption={(
        props,
        { id, fullName, avatarUrl, designation, deactivatedAt, country, city },
      ) => (
        <ListItem {...props} key={id}>
          <MemberInfoCell
            // eslint-disable-next-line
            hideAvatar={(props as PlainObject)['data-option-index'] > 2000}
            userInfo={{
              fullName: fullName,
              avatarUrl: avatarUrl,
              stream: designation,
              country: country,
              city: city,
            }}
            disabled={!!deactivatedAt}
          />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search"
          InputProps={{
            ...(params.InputProps || {}),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
