import React, { useCallback, useEffect } from 'react';
import Papaparse from 'papaparse';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';

import { PageWrapper } from '../../components';
import { useTheme } from '@mui/material';
import { userDataSelectors } from '../../redux/userData';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';
import { downloadCsv } from '../reports/Reports.utils';
import {
  adminSelectors,
  fetchAdminReports,
  fetchSharedMetricsInconsistencies,
} from '../../redux/admin';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers';
import { createDateFromYYYYMMFormat } from '../../utils';
import { DateTime } from 'luxon';

const initialValues = {
  period: 202401,
};

const Admin = (): JSX.Element => {
  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(PageTitle.admin);
  }, [setPageTitle]);

  const dispatch = useAppDispatch();

  const { spacing } = useTheme();

  const adminReports = useAppSelector(adminSelectors.getAdminReports);
  const sharedMetricsInconsistencies = useAppSelector(
    adminSelectors.getSharedMetricsInconsistencies,
  );

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      period: yup.number().required('Cannot be empty'),
    }),
    onSubmit: () => {
      //
    },
  });

  const handleDownloadNovCsv = useCallback(() => {
    const reportData = adminReports.map((report) => {
      return {
        ['Department']: report['Department'],
        ['Bonus type']: report['Bonus type'],
        ['Bonus type (from department)']:
          report['Bonus type (from department)'],
        ['Full name']: report['Full name'],
        ['Metric']: report['Metric'],
        ['Bonus amount']: report['Bonus amount'],
        ['Frequency']: report['Frequency'],
        ['Location']: report['Location'],
      };
    });

    const csvData = Papaparse.unparse(reportData, {
      quotes: true,
      columns: [
        'Department',
        'Bonus type',
        'Bonus type (from department)',
        'Full name',
        'Metric',
        'Bonus amount',
        'Frequency',
        'Location',
      ],
    });

    downloadCsv(csvData, 'Admin report');
  }, [adminReports]);

  const requestSharedMetricsInconsistencies = useCallback(() => {
    dispatch(fetchSharedMetricsInconsistencies());
  }, [dispatch]);

  const downloadCsvSharedMetricsInconsistencies = useCallback(() => {
    const sharedMetricsInconsistenciesProc = sharedMetricsInconsistencies.map(
      (report) => {
        return {
          ['Message']: report['msg'],
          ['Shared metric name']: report['name'],
          ['User name']: report['fullName'],
          ['Best case']: report['bestCase'],
          ['Worst case']: report['worstCase'],
          ['Period']: report['period'],
        };
      },
    );

    const csvData = Papaparse.unparse(sharedMetricsInconsistenciesProc, {
      quotes: true,
      columns: [
        'Message',
        'Shared metric name',
        'User name',
        'Best case',
        'Worst case',
        'Period',
      ],
    });

    downloadCsv(csvData, 'Shared metrics incons');
  }, [sharedMetricsInconsistencies]);

  const user = useAppSelector(userDataSelectors.getUserData);

  useEffect(() => {
    dispatch(fetchAdminReports({ period: formik.values.period }));
  }, [dispatch, formik.values.period]);

  return (
    <PageWrapper>
      <Card
        variant="outlined"
        sx={{
          marginX: 'auto',
          padding: 3,
          borderRadius: `${spacing(1)} ${spacing(1)} 0 0`,
        }}
      >
        <Grid container spacing={2}>
          <Typography variant="h2" mx={2} mt={2}>
            Reports for Olesia
          </Typography>
          {user?.email === 'olesia.ovcharenko@agileengine.com' && (
            <>
              <Grid item xs={12}>
                <Alert severity="success">
                  Hey Olesia! Click here to load reports
                </Alert>
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  views={['year', 'month']}
                  onChange={(date: Date | null) =>
                    formik.setFieldValue(
                      'period',
                      DateTime.fromJSDate(date || new Date()).toFormat(
                        'yyyyMM',
                      ),
                    )
                  }
                  value={createDateFromYYYYMMFormat(formik.values.period)}
                  format="MM/yyyy"
                  label="Period"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={handleDownloadNovCsv}
                  size="small"
                  variant="text"
                  sx={{ ml: 1 }}
                >
                  Get report
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Alert severity="success">
                  Hey Olesia! Click here to get inconsistencies in shared
                  metrics
                </Alert>
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={requestSharedMetricsInconsistencies}
                  size="small"
                  variant="text"
                  sx={{ ml: 1 }}
                >
                  Get inconsistencies
                </Button>
                <Button
                  onClick={downloadCsvSharedMetricsInconsistencies}
                  size="small"
                  variant="text"
                  sx={{ ml: 1 }}
                >
                  Download inconsistencies
                </Button>
              </Grid>

              <Grid>
                {sharedMetricsInconsistencies.map((i, key) => {
                  return (
                    <Typography
                      variant={
                        i.msg ===
                        'there are inconsistencies in the following group:'
                          ? 'h2'
                          : 'body1'
                      }
                      mx={2}
                      mt={2}
                      key={key}
                    >
                      {`${i.msg} Worst Case: ${i.worstCase || '_'} Best Case: ${
                        i.bestCase || '_'
                      } Metric: ${i.name} User: ${i.fullName}`}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </PageWrapper>
  );
};

export default Admin;
