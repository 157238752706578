import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { AlertCategory, AlertType, CategoryLabels } from '../../../types';
import { FormikErrors } from 'formik';

interface Props {
  value: AlertCategory[];
  handleChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<AlertType>>;
  error: boolean;
}
export const AlertCategorySelect = ({ value, handleChange, error }: Props) => {
  const alertCategoryOptions = useMemo(
    () => Object.entries(AlertCategory).map(([, value]) => value),
    [],
  );

  const handleAlertCategoryChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, option: AlertCategory[]) =>
      handleChange('categories', option),
    [handleChange],
  );

  return (
    <Autocomplete
      options={alertCategoryOptions}
      onChange={handleAlertCategoryChange}
      getOptionLabel={(option) => CategoryLabels[option]}
      value={value}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Box component="div" width="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                component="p"
                sx={{ fontSize: '14px', color: 'text.primary' }}
              >
                {CategoryLabels[option]}
              </Typography>
            </Stack>
          </Box>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name="categories"
          required
          label="Alert category"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Please select"
          error={error}
        />
      )}
      sx={{
        '.MuiInputBase-adornedStart': { padding: '9px' },
        '& .MuiChip-root': { borderRadius: 4 },
        marginBottom: 1,
      }}
      multiple
    />
  );
};
