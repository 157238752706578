import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiEndpoints } from '../../constants/api';
import { DefaultServerResponse } from '../../types/server-response';
import {
  RockyUser,
  TermsOfUserAcceptResp,
  UserAccountsCountDto,
  UserWithPermissions,
} from '../../types';
import { userDataStoreKey } from './userData.const';
import { AuthService } from '../../services/auth-service';
import {
  HistoricalDataUpdateBody,
  UserKpisBonusShare,
} from '../../types/contractor';
import { notificationAlert } from '../notifications';
import { formatErrorMessage, is } from '../../utils';
import { getAllAssignmentsWithHistoricalData } from '../contractor';
import { RockyApiService } from '../../services/rocky-api';
import { TERMS_OF_USE_VERSION } from '../../components/termsOfUse/constants';

function serializeUserResponse(
  response: DefaultServerResponse<UserWithPermissions>,
): DefaultServerResponse<UserWithPermissions> {
  const { data, status, statusText } = response;

  return {
    data,
    status,
    statusText,
  };
}

export const fetchUserData = createAsyncThunk(
  `${userDataStoreKey}/fetchUserData`,
  async () => {
    try {
      const response = await axios.get(apiEndpoints.userProfile());
      return serializeUserResponse(response);
    } catch (err) {
      AuthService.clearToken();

      throw new Error(String(err));
    }
  },
);

export const fetchUsersList = createAsyncThunk(
  `${userDataStoreKey}/fetchUsersList`,
  async () => {
    try {
      const response = await axios.get<UserWithPermissions[]>(
        apiEndpoints.usersList(),
      );

      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  },
);

export const fetchAllUsersList = createAsyncThunk(
  `${userDataStoreKey}/fetchAllUsersList`,
  async () => {
    try {
      const response = await axios.get<UserWithPermissions[]>(
        apiEndpoints.allUsersList(),
      );

      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  },
);

export const fetchUserAccountsCounts = createAsyncThunk(
  `${userDataStoreKey}/fetchUserAccountsCounts`,
  async () => {
    try {
      const response = await axios.get<UserAccountsCountDto>(
        apiEndpoints.userAccountsCount(),
      );

      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  },
);

export const fetchRockyUserData = createAsyncThunk(
  `${userDataStoreKey}/fetchRockyUserData`,
  async (_, thunkAPI) => {
    try {
      if (is.preprod) {
        return {
          latestTermsOfUseVersionAccepted: TERMS_OF_USE_VERSION,
        } as RockyUser;
      }

      const response = await RockyApiService.request<RockyUser>({
        method: 'get',
        url: `/users/current-user`,
        headers: {
          Authorization: `Bearer ${AuthService.getToken()}` ?? '',
        },
      });

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);

export const fetchUserBonusPlan = createAsyncThunk(
  `${userDataStoreKey}/fetchUserBonusPlan`,
  async (userId: string) => {
    try {
      const response = await axios.get<UserKpisBonusShare>(
        apiEndpoints.fetchUserBonusPlan(userId),
      );
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  },
);

export const updateUserBonusPlan = createAsyncThunk(
  `${userDataStoreKey}/updateUserBonusPlan`,
  async ({ userId, assignments }: HistoricalDataUpdateBody, thunkAPI) => {
    try {
      await axios.post(apiEndpoints.updateUserBonusPlan(), {
        userId,
        assignments,
      });

      thunkAPI.dispatch(getAllAssignmentsWithHistoricalData(userId));
      thunkAPI.dispatch(notificationAlert('Updated historical data'));

      thunkAPI.dispatch(fetchUserBonusPlan(userId));
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(
          formatErrorMessage(err, 'Updating bonus plan has failed'),
          {
            variant: 'error',
          },
        ),
      );
      throw new Error(String(err));
    }
  },
);

export const acceptTermsOfUse = createAsyncThunk(
  `${userDataStoreKey}/acceptTermsOfUse`,
  async (version: string, thunkAPI) => {
    try {
      if (is.preprod) {
        return { version: TERMS_OF_USE_VERSION } as TermsOfUserAcceptResp;
      }

      const response = await RockyApiService.request<TermsOfUserAcceptResp>({
        method: 'post',
        data: { version },
        url: `/terms-of-use/accept`,
        headers: {
          Authorization: `Bearer ${AuthService.getToken()}` ?? '',
        },
      });

      return response.data;
    } catch (err) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(String(err));
    }
  },
);
