import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  AlertResolvedStatus,
  AlertType,
  CategoryLabels,
  ResolveAlertType,
  ResolvedStatusLabel,
} from '../../types';
import { useAppDispatch } from '../../redux/hooks';
import { resolveAlert } from '../../redux/alert';
import { currDayWithOptionalYear, millisecondsToDays } from '../../utils';
import { DateTime } from 'luxon';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

interface Props {
  alertToResolve: AlertType;
  handleCloseModal: () => void;
}

const ResolveAlertModal: React.FC<Props> = ({
  handleCloseModal,
  alertToResolve,
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<ResolveAlertType>({
    initialValues: {
      resolvedStatus: AlertResolvedStatus.POSITIVE,
      resolvedSummary: '',
      id: alertToResolve.id,
    },
    validationSchema: yup.object({
      resolvedStatus: yup
        .mixed<AlertResolvedStatus>()
        .oneOf(Object.values(AlertResolvedStatus))
        .required(),
      resolvedSummary: yup.string().max(3000).required(),
    }),
    onSubmit: (values) => {
      dispatch(resolveAlert(values));
      handleCloseModal();
    },
    validateOnMount: false,
    validateOnChange: false,
  });

  const onModalClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event?.stopPropagation();
    },
    [],
  );

  const handleResolvedStatusChange = useCallback(
    (
      _: React.SyntheticEvent<Element, Event>,
      option: AlertResolvedStatus | null,
    ) => formik.setFieldValue('resolvedStatus', option),
    [formik],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleCloseModal}
      open={Boolean(alertToResolve)}
      scroll="body"
      onClick={onModalClick}
    >
      <DialogTitle>Resolve {alertToResolve.account.name} alert</DialogTitle>
      <CloseOutlinedIcon
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 22,
          top: 21,
          color: 'grey',
          cursor: 'pointer',
        }}
        onClick={handleCloseModal}
        fontSize="small"
      ></CloseOutlinedIcon>
      <DialogContent sx={{ maxHeight: 600 }}>
        {alertToResolve.categories.map((category) => (
          <Chip
            key={category}
            variant="filled"
            label={CategoryLabels[category]}
            sx={{
              marginRight: '8px',
              borderRadius: '50px',
              marginBottom: '4px',
            }}
          />
        ))}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 1,
          }}
        >
          <Typography whiteSpace="nowrap" variant="body1" color="grey">
            {(() => {
              const milliseconds = DateTime.now().diff(
                DateTime.fromISO(alertToResolve.createdAt as any),
              ).milliseconds;

              if (milliseconds > 0) {
                if (milliseconds < 1000 * 60 * 60 * 24) {
                  return 'Opened today';
                }

                return `Open for ${millisecondsToDays(milliseconds)} days`;
              }

              return 'Error';
            })()}
            &nbsp;•&nbsp; ETA on resolving&nbsp;
            {currDayWithOptionalYear(alertToResolve.etaForResolve)}
          </Typography>
        </Box>

        <Autocomplete
          id="resolvedStatus"
          onChange={handleResolvedStatusChange}
          clearIcon={null}
          componentsProps={{ clearIndicator: { disabled: true } }}
          options={Object.values(AlertResolvedStatus)}
          getOptionLabel={(option) => ResolvedStatusLabel[option]}
          value={formik.values.resolvedStatus}
          sx={{
            marginTop: 3,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="resolvedStatus"
              required
              label="Resolution"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Please select"
            />
          )}
        />
        <TextField
          id="resolvedSummary"
          name="resolvedSummary"
          sx={{
            width: '100%',
            marginBottom: 1,
            marginTop: 3,
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          required
          label="Lessons learned"
          placeholder="Please summarize the resolution from the email thread for quick history overview"
          InputLabelProps={{
            shrink: true,
          }}
          minRows={6}
          multiline
          onChange={formik.handleChange}
          value={formik.values.resolvedSummary}
          error={
            formik.submitCount > 0 && Boolean(formik.errors.resolvedSummary)
          }
        />
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            backgroundColor: '#f7f9fd',
            marginTop: 3,
            p: 2,
            color: 'grey',
          }}
          gap={2}
        >
          <ArchiveOutlinedIcon />
          <Typography>
            Alert will be moved to archive. Email thread will not be updated
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ alignItems: 'stretch', justifyContent: 'end' }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          justifyContent="space-between"
          gap={1}
        >
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            variant="contained"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            Resolve
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ResolveAlertModal;
