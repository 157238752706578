import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Link,
  List,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';

import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import GoogleDriveLogo from '../../assets/google-drive.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSelectedAccount, getSelectedProject } from '../../redux/account';
import { useTitle } from '../../hooks/useTitle';

import { PageTitle } from '../../utils/pageTitles';
import { paths } from '../../constants/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import { AbsoluteTableSortLabel } from '../../features/teamMemberView/components/styledComponents/AbsoluteTableSortLabel';

import { CategoryLabels, SortOrder, AlertType } from '../../types';
import { RisksColumns } from '../../redux/alert/alert.types';
import { alertSlice, alertDataSelector, fetchAlerts } from '../../redux/alert';
import { DateTime } from 'luxon';
import { currDayWithOptionalYear, millisecondsToDays } from '../../utils';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import { DataState } from '../../types/asyncData';
import { ReactComponent as SunIcon } from '../../assets/sun.svg';
import ResolveAlertModal from '../../features/alerts/ResolveAlertModal';
import EditIcon from '@mui/icons-material/Edit';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { AlertPopover } from './alertPopover';
import { notificationAlert } from '../../redux/notifications';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const columns = [
  RisksColumns.ALERT_DETAILS,
  RisksColumns.REASON,
  RisksColumns.RAISED_ON,
  RisksColumns.RESOLVE_BY,
];

export const Risks: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const alertId = location.state as string;
  const account = useAppSelector(getSelectedAccount);
  const alerts = useAppSelector(alertDataSelector.getAlerts);
  const alertsLoading = useAppSelector(alertDataSelector.getAlertsLoading);

  const project = useAppSelector(getSelectedProject);
  const sortBy = useAppSelector(alertDataSelector.getSortBy);
  const sortOrder = useAppSelector(alertDataSelector.getSortOrder);

  const [resolvedStatus, setResolvedStatus] = useState<boolean>(false);
  const [alertToResolve, setAlertToResolve] = useState<AlertType>();

  useEffect(() => {
    if (alertId) {
      setAlertToResolve(alerts?.find((alert) => alert.id === alertId));
    }
  }, [alertId, alerts]);

  const handleCloseModal = useCallback(
    (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (event) event?.stopPropagation();
      setAlertToResolve(undefined);
    },
    [setAlertToResolve],
  );

  const setPageTitle = useTitle();
  useEffect(() => {
    setPageTitle(
      `${account?.name ?? PageTitle.portfolio} | ${PageTitle.risks}`,
    );
  }, [setPageTitle, account]);

  const onAddAlert = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event?.stopPropagation();
      dispatch(alertSlice.actions.resetAlert());
      navigate(`/${account?.id}/${paths.alert}/create`);
    },
    [navigate, account, dispatch],
  );

  const handleColumnSort = useCallback(
    (columnName: string) => {
      const newSortOrder =
        columnName === sortBy ? 1 - sortOrder : SortOrder.Ascending;
      dispatch(
        alertSlice.actions.setSortBy({
          sortBy: columnName as any,
          sortOrder: newSortOrder,
        }),
      );
    },
    [dispatch, sortOrder, sortBy],
  );

  useEffect(() => {
    if (!account?.id) return;

    dispatch(
      fetchAlerts({
        resolvedStatus: resolvedStatus === false ? undefined : 'no-filtering',
        accountId: account.id,
      }),
    );
  }, [resolvedStatus, dispatch, account?.id]);

  const flipResolvedStatus = useCallback(() => {
    setResolvedStatus(!resolvedStatus);
  }, [setResolvedStatus, resolvedStatus]);

  const viewAlertDetails = useCallback(
    (alert: AlertType) => {
      dispatch(alertSlice.actions.resetAlert());
      navigate(
        `/${account?.id}/${paths.alert}/${alert.id}/${
          alert.resolvedStatus ? 'view' : 'edit'
        }`,
      );
    },
    [account?.id, navigate, dispatch],
  );

  if (alerts === null || alertsLoading === DataState.Pending) {
    return (
      <Stack
        sx={{
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderTop: 'none',
          borderRadius: '8px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: '600px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="secondary" />
      </Stack>
    );
  }

  if (alerts.length === 0) {
    return (
      <Stack
        sx={{
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderTop: 'none',
          borderRadius: '8px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: '600px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <SunIcon />

        <ShowResolvedAlertsButton
          flipResolvedStatus={flipResolvedStatus}
          resolvedStatus={resolvedStatus}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Link
            variant="body2"
            href="https://docs.google.com/document/d/1ApWXL4lIYJfdWTIWpyQarFbnB2BhaFmbeh_bEQWEK4M/edit?usp=sharing"
            marginRight={'16px'}
            target="_blank"
          >
            Alert resolving checklist ↗
          </Link>
          <Link
            variant="body2"
            href="https://docs.google.com/document/d/1G-19nwwKDM2-ny2r5DREjp1KXvfMlnDzx1CuR9n81Ys/edit?usp=sharing"
            target="_blank"
          >
            Status convention ↗
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            component={Link}
            size="medium"
            variant="contained"
            color="primary"
            startIcon={<AddAlertOutlinedIcon />}
            onClick={onAddAlert}
            target="_blank"
          >
            Add Alert
          </Button>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        padding: '22px 16px',
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderTop: 'none',
        borderRadius: '8px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Typography variant="h3" mb="22px">
        Project risk alerts
      </Typography>
      <Table
        sx={(theme) => ({
          background: theme.palette.background.paper,
          borderRadius: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
        })}
      >
        <TableHead>
          <TableRow>
            {columns.map((columnName: RisksColumns) => (
              <TableCell
                sx={{
                  width: (theme: Theme) => theme.spacing(15),
                  ...(columnName === RisksColumns.ALERT_DETAILS && {
                    minWidth: 250,
                    paddingInlineStart: 0,
                  }),
                }}
                key={columnName}
                variant="head"
                align="left"
                colSpan={1}
              >
                {![RisksColumns.RAISED_ON, RisksColumns.RESOLVE_BY].includes(
                  columnName,
                ) ? (
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'grey',
                    }}
                    variant="h4"
                    component="p"
                  >
                    {columnName}
                  </Typography>
                ) : (
                  <AbsoluteTableSortLabel
                    direction={
                      sortOrder === SortOrder.Ascending ? 'asc' : 'desc'
                    }
                    active={sortBy === columnName}
                    onClick={() => handleColumnSort(columnName)}
                    sx={{ mr: 0 }}
                  >
                    <Typography
                      whiteSpace="nowrap"
                      variant="h4"
                      component="p"
                      color="grey"
                    >
                      {columnName}
                    </Typography>
                  </AbsoluteTableSortLabel>
                )}
              </TableCell>
            ))}
            <TableCell colSpan={1}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            'tr:not(:last-child, .projectRow)': {
              boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
            },
            '.projectRow': {
              position: 'sticky',
              top: '56px',
              zIndex: 2,
            },
          }}
        >
          {alerts.map((alert) => (
            <AlertRow
              alert={alert}
              setAlertToResolve={setAlertToResolve}
              viewAlertDetails={viewAlertDetails}
              key={alert.id}
            />
          ))}
        </TableBody>
      </Table>
      <ShowResolvedAlertsButton
        flipResolvedStatus={flipResolvedStatus}
        resolvedStatus={resolvedStatus}
      />
      <List>
        <Button
          component={Link}
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<AddAlertOutlinedIcon />}
          onClick={onAddAlert}
          target="_blank"
          sx={{ marginRight: '32px' }}
        >
          Add Alert
        </Button>

        <Button
          component={Link}
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<img src={GoogleDriveLogo} alt="google-drive" />}
          href={`https://drive.google.com/drive/u/0/search?q=%22${
            project?.name ?? account?.name
          }%22%20%22account%20summary%22%20type:spreadsheet`}
          target="_blank"
        >
          Open account summary
        </Button>
      </List>

      {alertToResolve && (
        <ResolveAlertModal
          handleCloseModal={handleCloseModal}
          alertToResolve={alertToResolve}
        />
      )}
    </Stack>
  );
};

interface ShowResolvedalertsButtonProps {
  flipResolvedStatus: () => void;
  resolvedStatus: boolean;
}

const ShowResolvedAlertsButton = ({
  flipResolvedStatus,
  resolvedStatus,
}: ShowResolvedalertsButtonProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <DoneAllIcon
        sx={{
          width: '20px',
          color: '#707070',
          marginBottom: '3px',
          cursor: 'pointer',
        }}
        onClick={flipResolvedStatus}
      />
      <Typography
        variant="body1"
        color="text.primary"
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          marginRight: 'auto',
        }}
        p={1}
        display={'inline'}
        onClick={flipResolvedStatus}
      >
        {resolvedStatus === false
          ? 'Show resolved alerts'
          : 'Show unresolved alerts only'}
      </Typography>
    </Box>
  );
};

const AlertRow = ({
  alert,
  viewAlertDetails,
  setAlertToResolve,
}: {
  alert: AlertType;
  viewAlertDetails: (alert: AlertType) => void;
  setAlertToResolve: React.Dispatch<
    React.SetStateAction<AlertType | undefined>
  >;
}) => {
  const dispatch = useAppDispatch();

  const { etaForResolveStr, etaForResolveColor } = (() => {
    const milliseconds = DateTime.fromISO(alert.etaForResolve as any).diffNow()
      .milliseconds;

    if (milliseconds > 0) {
      if (milliseconds < 1000 * 60 * 60 * 24) {
        return {
          etaForResolveStr: 'Today',
          etaForResolveColor: 'red',
        };
      }

      return {
        etaForResolveStr: `${millisecondsToDays(milliseconds)} days left`,
        etaForResolveColor: 'grey',
      };
    }

    return {
      etaForResolveStr: 'Overdue',
      etaForResolveColor: 'red',
    };
  })();

  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const alertAnchor = React.useRef<HTMLElement | null>(null);

  const [openMailPopover, setOpenMailPopover] = React.useState<boolean>(false);
  const alertMailAnchor = React.useRef<HTMLElement | null>(null);

  const viewCurrAlertDetails = useCallback(
    (event?: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
      if (event) event?.stopPropagation();
      viewAlertDetails(alert);
    },
    [viewAlertDetails, alert],
  );

  const setCurrAlertToResolve = useCallback(
    (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (event) event?.stopPropagation();
      setAlertToResolve(alert);
    },
    [setAlertToResolve, alert],
  );

  const handleMouseOver = useCallback(() => {
    setOpenPopover(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenPopover(false);
  }, []);

  const handleAlertMailMouseOver = useCallback(() => {
    setOpenMailPopover(true);
  }, []);

  const handleAlertMailClose = useCallback(() => {
    setOpenMailPopover(false);
  }, []);

  const handleEmailSubjectClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      event?.stopPropagation();
      navigator.clipboard.writeText(
        `🚨 Alert for ${alert.account?.name}: ${alert.categories
          .map((cat) => CategoryLabels[cat])
          .join(', ')}`,
      );
      dispatch(notificationAlert('Alert email subject copied!'));
    },
    [alert, dispatch],
  );

  return (
    <TableRow
      key={alert.id}
      sx={{
        cursor: 'pointer',
        transition: 'background 0.25s',
        '&:hover': {
          background: (theme) => theme.palette.action.hover,

          '*::-webkit-scrollbar-thumb': {
            borderColor: (theme) => theme.palette.action.hover,
          },
        },
      }}
    >
      <TableCell onClick={viewCurrAlertDetails} sx={{ paddingInlineStart: 0 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            overflow: 'elipsis',
            gap: 1,
          }}
        >
          <Box ref={alertMailAnchor}>
            <IconButton
              onMouseEnter={handleAlertMailMouseOver}
              onMouseLeave={handleAlertMailClose}
              onClick={handleEmailSubjectClick}
              sx={{ padding: 0 }}
            >
              {openMailPopover ? (
                <FileCopyOutlinedIcon
                  sx={{
                    fontSize: '1.25rem',
                    color: 'primary.main',
                  }}
                />
              ) : (
                <EmailOutlinedIcon
                  color="secondary"
                  sx={{ fontSize: '1.25rem' }}
                />
              )}
            </IconButton>
            <AlertPopover
              anchorEl={alertMailAnchor}
              handleClose={handleAlertMailClose}
              handleMouseOver={handleAlertMailMouseOver}
              isOpen={openMailPopover}
            >
              <Stack
                sx={{
                  flexWrap: 'wrap',
                  maxWidth: '300px',
                }}
                onClick={handleEmailSubjectClick}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    color: 'text.secondary',
                    justifyContent: 'space-between',
                    gap: 1,
                    marginBottom: 1,
                  }}
                >
                  <Typography>Email subject</Typography>
                  <Typography>
                    {currDayWithOptionalYear(alert.createdAt)}
                  </Typography>
                </Box>
                <Typography>{`🚨 Alert for ${
                  alert.account?.name
                }: ${alert.categories
                  .map((cat) => CategoryLabels[cat])
                  .join(', ')}`}</Typography>
                <Typography sx={{ color: 'primary.main', marginTop: 1 }}>
                  Click to copy to clipboard
                </Typography>
              </Stack>
            </AlertPopover>
          </Box>

          <Box ref={alertAnchor}>
            <IconButton sx={{ padding: 0 }}>
              <FormatAlignJustifyIcon
                color="secondary"
                sx={{ fontSize: '1.25rem' }}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleClose}
              />
            </IconButton>
            <AlertPopover
              anchorEl={alertAnchor}
              handleClose={handleClose}
              handleMouseOver={handleMouseOver}
              isOpen={openPopover}
            >
              <Stack
                sx={{
                  flexWrap: 'wrap',
                  maxWidth: '300px',
                }}
                onClick={handleEmailSubjectClick}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    color: 'text.secondary',
                    justifyContent: 'space-between',
                    gap: 1,
                    marginBottom: 1,
                  }}
                >
                  <Typography>Problem statement</Typography>
                </Box>
                <Typography>{alert.problemStatement}</Typography>
              </Stack>
            </AlertPopover>
          </Box>

          <Typography>{alert.name}</Typography>
        </Box>
      </TableCell>

      <TableCell onClick={viewCurrAlertDetails}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: '500px',
            overflow: 'scroll',
            marginBottom: '-10px',
          }}
        >
          {alert.categories.map((category) => (
            <Chip
              key={category}
              variant="filled"
              label={CategoryLabels[category]}
              sx={{ marginRight: '8px', borderRadius: '50px' }}
            />
          ))}
        </Box>
      </TableCell>
      <TableCell
        onClick={viewCurrAlertDetails}
        sx={{
          textAlign: 'center',
          width: (theme: Theme) => theme.spacing(15),
        }}
      >
        <Box textAlign="left">
          <Typography whiteSpace="nowrap" variant="body1">
            {currDayWithOptionalYear(alert.createdAt as any)}
          </Typography>
          <Typography whiteSpace="nowrap" variant="body1" color="grey">
            {(() => {
              const milliseconds = DateTime.now().diff(
                DateTime.fromISO(alert.createdAt as any),
              ).milliseconds;

              if (milliseconds > 0) {
                if (milliseconds < 1000 * 60 * 60 * 24) {
                  return 'Last 24 hrs';
                }

                return `${millisecondsToDays(milliseconds)} days ago`;
              }

              return 'Error';
            })()}
          </Typography>
        </Box>
      </TableCell>
      <TableCell onClick={viewCurrAlertDetails}>
        <Typography whiteSpace="nowrap" variant="body1">
          {currDayWithOptionalYear(alert.etaForResolve as any)}
        </Typography>
        <Typography
          whiteSpace="nowrap"
          variant="body1"
          color={etaForResolveColor}
        >
          {etaForResolveStr}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          textAlign: 'end',
        }}
        onClick={viewCurrAlertDetails}
      >
        {!Boolean(alert.resolvedStatus) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <IconButton aria-label="Expand/collapse icon" size="small">
              <EditIcon color="primary" />
            </IconButton>
            <Button
              component={Link}
              size="medium"
              variant="contained"
              color="primary"
              target="_blank"
              onClick={setCurrAlertToResolve}
              sx={{ zIndex: 2 }}
            >
              Resolve
            </Button>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};
