import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adminStoreKey } from '.';
import { API_STATUS } from '../../constants';
import { ReportType } from '../../types/reports';
import { AdminState } from './admin.types';
import {
  fetchAdminReports,
  fetchSharedMetricsInconsistencies,
} from './admin.thunks';

const initialState: AdminState = {
  apiStatus: API_STATUS.IDLE,
  adminReports: [],
  sharedMetricsInconsistencies: [],
};

export const adminSlice = createSlice({
  name: adminStoreKey,
  initialState,
  reducers: {
    clearReports: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminReports.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        fetchAdminReports.fulfilled,
        (state, action: PayloadAction<ReportType[]>) => {
          state.apiStatus = API_STATUS.IDLE;
          state.adminReports = action.payload;
        },
      )
      .addCase(fetchAdminReports.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
        state.adminReports = [];
      });

    builder
      .addCase(
        fetchSharedMetricsInconsistencies.fulfilled,
        (
          state,
          action: PayloadAction<
            {
              msg?: string;
              worstCase?: number;
              bestCase?: number;
              name?: string;
              period?: number;
              fullName?: string;
            }[]
          >,
        ) => {
          state.sharedMetricsInconsistencies = action.payload;
        },
      )
      .addCase(fetchSharedMetricsInconsistencies.rejected, (state) => {
        state.sharedMetricsInconsistencies = [];
      });
  },
});

export const { clearReports } = adminSlice.actions;
