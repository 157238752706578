import React, { useCallback, useEffect, useMemo } from 'react';
import { PageWrapper } from '../../components';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import {
  fetchAccount,
  getSelectedAccount,
  getLoading,
} from '../../redux/account';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from '../../constants/paths';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  Stack,
  Link,
} from '@mui/material';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { COLORS } from '../../utils/colors';
import { AlertCategorySelect } from './components/AlertCategorySelect';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { OwnerSelect } from './components/OwnerSelect';
import { fetchAllUsersList, userDataSelectors } from '../../redux/userData';

import {
  AlertCategory,
  UpsertAlertType,
  UserForSearch,
  UserWithPermissions,
} from '../../types';
import {
  createAlert,
  fetchAlert,
  updateAlert,
} from '../../redux/alert/alert.thunks';

import {
  addDaysToDate,
  dateToIsoString,
  formatStringToDate,
} from '../../utils';
import { alertDataSelector } from '../../redux/alert/alert.selectors';
import { DataState } from '../../types/asyncData';
import { alertSlice } from '../../redux/alert/alert.slice';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { EmailRecipientList } from './components/EmailRecipientList';

import CachedIcon from '@mui/icons-material/Cached';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { EmailRecipientSearch } from './components/EmailRecipientSearch';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

interface AlertPropType {
  isEdit: boolean;
}

export const Alert: React.FC<AlertPropType> = ({ isEdit }: AlertPropType) => {
  const { accountId, alertId } = useParams();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loading = useAppSelector(getLoading);
  const account = useAppSelector(getSelectedAccount);
  const currentUser = useAppSelector(userDataSelectors.getUserData);
  const alertLoading = useAppSelector(alertDataSelector.getAlertLoading);
  const alertIsNoNavigation = useAppSelector(
    alertDataSelector.getAlertIsNoNavigation,
  );
  const alertData = useAppSelector(alertDataSelector.getAlert);
  const usersList = useAppSelector(userDataSelectors.getAllUsersList);

  const alertInitialState: UpsertAlertType = useMemo(
    () => ({
      categories: [],
      name: '',
      etaForResolve: '',
      problemStatement: '',
      whatWasDone: '',
      actionPlan: '',
      risksAndBlockers: '',
      accountId: '',
      resolvedStatus: null,
      resolvedSummary: null,
      resolvedAt: null,
      owner: null,
      ownerId: null,
      authorId: '',
      alertEmailRecipientsEmails: [],
    }),
    [],
  );

  const getAlertInitialState = useCallback(
    (data: Partial<UpsertAlertType>): UpsertAlertType => {
      const alert = {
        ...alertInitialState,
        ...data,
      };
      return alert;
    },
    [alertInitialState],
  );

  const initialValues = useMemo(() => {
    if (isEdit && alertData) {
      return getAlertInitialState(alertData as any);
    }
    return getAlertInitialState({
      accountId,
      owner: currentUser,
      etaForResolve: addDaysToDate(new Date(), 7).toISOString(),
      authorId: currentUser?.id,
      alertEmailRecipientsEmails: _.uniq([
        account?.portfolioDeliveryManager?.email,
        currentUser?.email,
        account?.deliveryManager?.email,
      ]).filter((email) => email !== undefined) as string[],
    });
  }, [
    accountId,
    isEdit,
    currentUser,
    alertData,
    getAlertInitialState,
    account,
  ]);

  const alertValidationSchema = yup.object({
    categories: yup
      .array(
        yup
          .mixed<AlertCategory>()
          .oneOf(Object.values(AlertCategory))
          .required(),
      )
      .min(1)
      .ensure(),
    owner: yup
      .object({
        id: yup.string(),
      })
      .required(),
    etaForResolve: yup.date().required(),
    name: yup.string().required(),
    problemStatement: yup.string().required(),
    whatWasDone: yup.string().required(),
    actionPlan: yup.string().required(),
    risksAndBlockers: yup.string().required(),
  });

  const formik = useFormik<UpsertAlertType>({
    initialValues,
    enableReinitialize: true,
    validationSchema: alertValidationSchema,
    onSubmit: (values) => {
      const alert: UpsertAlertType = {
        ...values,
        ownerId: values?.owner?.id ?? null,
      };
      if (isEdit) {
        return dispatch(updateAlert(alert));
      }
      return dispatch(createAlert(alert));
    },
  });

  const usersListForSearch: UserWithPermissions[] = useMemo(() => {
    if (
      !isEdit &&
      formik.values.alertEmailRecipientsEmails.length &&
      usersList
    ) {
      return usersList?.filter(
        (user) =>
          !formik.values.alertEmailRecipientsEmails.includes(user.email),
      );
    }
    return usersList ?? [];
  }, [isEdit, formik.values.alertEmailRecipientsEmails, usersList]);

  useEffect(() => {
    if (alertLoading === DataState.Fulfilled && !alertIsNoNavigation) {
      navigate(`${paths.portfolio}/${accountId}/${paths.risks}`);
      dispatch(
        alertSlice.actions.setAlertLoadingData({
          loading: DataState.Idle,
          isNoNavigation: true,
        }),
      );
    }
  }, [alertLoading, alertIsNoNavigation, accountId, navigate, dispatch]);

  useEffect(() => {
    dispatch(fetchAllUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (accountId) {
      if (isEdit && alertId) {
        dispatch(fetchAlert(alertId));
      }
      dispatch(
        fetchAccount({
          accountId,
          fetchAllMembers: true,
        }),
      );
    }
  }, [accountId, dispatch, alertId, isEdit]);

  // navigate to view only if the alert is resolved
  useEffect(() => {
    if (alertData && alertData.resolvedStatus) {
      navigate(`/${accountId}/${paths.alert}/${alertData.id}/view`);
    }
  }, [alertData, navigate, accountId]);

  const getBreadCrumbsPageName = useMemo(
    () => (account ? `Back to ${account.name}` : ``),
    [account],
  );

  const handleETADateChange = useCallback(
    (date: Date | null) =>
      formik.setFieldValue('etaForResolve', dateToIsoString(date)),
    [formik],
  );

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleEmailSearchChange = useCallback(
    (
      _: React.SyntheticEvent<Element, Event>,
      newValue: UserForSearch | null,
    ) => {
      if (newValue) {
        formik.setFieldValue('alertEmailRecipientsEmails', [
          ...formik.values.alertEmailRecipientsEmails,
          newValue.email,
        ]);
      }
    },
    [formik],
  );

  const removeHandleSelectedEmail = useCallback(
    (email?: string) => {
      formik.setFieldValue(
        'alertEmailRecipientsEmails',
        formik.values.alertEmailRecipientsEmails.filter(
          (recipient) => recipient !== email,
        ),
      );
    },
    [formik],
  );

  const setCurrAlertToResolve = useCallback(() => {
    formik.submitForm();
    navigate(`${paths.portfolio}/${accountId}/${paths.risks}`, {
      state: alertData?.id,
    });
  }, [accountId, navigate, formik, alertData]);

  if (!account || (isEdit && !alertData) || loading || !usersList.length) {
    return (
      <PageWrapper
        isLoading={loading || alertLoading === DataState.Pending}
      ></PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Breadcrumbs
        showPageLink={true}
        breadcrumbs={[]}
        rootPath={`${paths.portfolio}/${accountId}`}
        pageName={getBreadCrumbsPageName}
      />
      <Paper
        variant="outlined"
        sx={{
          marginTop: 5,
          paddingInlineStart: 5,
          paddingInlineRight: 0,
          '@media(max-width:768px)': {
            paddingRight: 2,
          },
        }}
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid
            item
            md={7}
            xs={12}
            sx={{
              padding: 2,
              '@media(max-width:768px)': {
                paddingRight: 2,
              },
            }}
          >
            <Grid container spacing={2}>
              <Box
                textAlign="right"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ marginInlineStart: 2, marginBlock: 2 }}
                gap={2}
              >
                {!isEdit && (
                  <NotificationsActiveIcon
                    fontSize="large"
                    color="error"
                    sx={{
                      backgroundColor: COLORS.HIGHLIGHTS.CRITICAL,
                      padding: 0.99,
                      borderRadius: 3,
                    }}
                  />
                )}
                <Typography color="text.primary" variant="h2">
                  {isEdit ? 'Edit alert for ' : 'New alert status for '}
                  {account.name}
                </Typography>
              </Box>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  name="name"
                  type="text"
                  required
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                    '& .MuiInputBase-input': {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  label="Summary / Title"
                  placeholder="Short summary to identify the alert"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.submitCount > 0 && Boolean(formik.errors.name)}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <AlertCategorySelect
                  handleChange={formik.setFieldValue}
                  value={formik.values.categories}
                  error={
                    formik.submitCount > 0 && Boolean(formik.errors.categories)
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  onChange={handleETADateChange}
                  value={formatStringToDate(formik.values.etaForResolve)}
                  format="d MMM yyyy"
                  label="ETA on resolving"
                  sx={{ width: '100%' }}
                  disablePast
                  slotProps={{
                    textField: {
                      helperText:
                        DateTime.fromISO(
                          formik.values.etaForResolve as any,
                        ).diffNow().milliseconds < 1 && 'Overdue',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <OwnerSelect
                  value={formik.values.owner}
                  alertEmailRecipientsEmails={
                    formik.values.alertEmailRecipientsEmails
                  }
                  handleChange={formik.setFieldValue}
                  error={formik.submitCount > 0 && Boolean(formik.errors.owner)}
                  isEdit={isEdit}
                  account={account}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="problemStatement"
                  name="problemStatement"
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                    '& .MuiInputBase-input': {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  required
                  label="Problem statement"
                  placeholder="The context of the status, any triggers which caused the status opening"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  minRows={6}
                  multiline
                  onChange={formik.handleChange}
                  value={formik.values.problemStatement}
                  error={
                    formik.submitCount > 0 &&
                    Boolean(formik.errors.problemStatement)
                  }
                />
              </Grid>
              {!isEdit && (
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  marginBlock={2}
                >
                  {[
                    {
                      label: 'Alert resolving checklist ↗',
                      value:
                        'https://docs.google.com/document/d/1ApWXL4lIYJfdWTIWpyQarFbnB2BhaFmbeh_bEQWEK4M/edit',
                    },
                    {
                      label: 'Status convention ↗',
                      value:
                        'https://docs.google.com/document/d/1G-19nwwKDM2-ny2r5DREjp1KXvfMlnDzx1CuR9n81Ys/edit',
                    },
                  ].map((link) => (
                    <Link
                      target="_blank"
                      href={link.value}
                      sx={{ marginInlineStart: 2 }}
                      key={link.label}
                    >
                      {link.label}
                    </Link>
                  ))}
                </Stack>
              )}
            </Grid>
          </Grid>
          {isEdit && (
            <Grid
              sx={{
                background: COLORS.HIGHLIGHTS.PROMINENT,
                padding: '38px 40px !important',
                marginInlineStart: 3,
                borderRadius: 2,
                fontFamily: 'sans-serif',
                lineHeight: '180%',
                '@media(max-width:768px)': {
                  margin: 2,
                },
              }}
              item
              xs={12}
              md={4}
            >
              <MailOutlineIcon sx={{ color: '#9e926f', marginBottom: 3 }} />
              <Stack rowGap="10px">
                <Stack columnGap="10px" flexDirection="row">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: '151%',
                      marginBottom: 3,
                    }}
                  >
                    Commmunicate alert resolving in the email thread to keep
                    everyone in the loop.
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: '151%',
                    marginBottom: 3,
                  }}
                >
                  Edited alert details here in Rocky will be used for historical
                  purposes an will not be populated into the initial thread.
                </Typography>
                <Stack
                  flexDirection="row"
                  columnGap="16px"
                  sx={{
                    lineHeight: '151%',
                    marginBottom: 3,
                  }}
                >
                  {[
                    {
                      label: 'Alert resolving checklist ↗',
                      value:
                        'https://docs.google.com/document/d/1ApWXL4lIYJfdWTIWpyQarFbnB2BhaFmbeh_bEQWEK4M/edit',
                    },
                  ].map((link) => (
                    <Link target="_blank" href={link.value} key={link.label}>
                      {link.label}
                    </Link>
                  ))}
                  <Typography variant="subtitle1"></Typography>
                </Stack>
                <Button
                  variant="contained"
                  onClick={setCurrAlertToResolve}
                  sx={{ width: 'fit-content' }}
                  disabled={!formik.isValid}
                >
                  Resolve alert
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Paper
        variant="outlined"
        sx={{ padding: 3, paddingInlineStart: 5, marginTop: 1 }}
      >
        <Box
          textAlign="right"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ marginBottom: 3, paddingBlock: 0.99 }}
          gap={2}
        >
          <Typography color="text.primary" variant="h2">
            Key insights and actions
          </Typography>
        </Box>
        <Grid item xs={12} paddingRight={2}>
          <Grid container spacing={3}>
            <Grid item md={7} xs={12}>
              <TextField
                id="whatWasDone"
                name="whatWasDone"
                sx={{
                  width: '100%',
                  marginBottom: 1,
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                required
                label="What was done?"
                placeholder="Actions that are already done by Engineering or HR teams"
                InputLabelProps={{
                  shrink: true,
                }}
                minRows={4}
                multiline
                onChange={formik.handleChange}
                value={formik.values.whatWasDone}
                error={
                  formik.submitCount > 0 && Boolean(formik.errors.whatWasDone)
                }
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <TextField
                id="actionPlan"
                name="actionPlan"
                sx={{
                  width: '100%',
                  marginBottom: 1,
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                required
                label="Action Plan"
                placeholder="If VPs or C-level are involved, facilitate conversations ad drive resolution of the account status towards success"
                InputLabelProps={{
                  shrink: true,
                }}
                minRows={4}
                multiline
                onChange={formik.handleChange}
                value={formik.values.actionPlan}
                error={
                  formik.submitCount > 0 && Boolean(formik.errors.actionPlan)
                }
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <TextField
                id="risksAndBlockers"
                name="risksAndBlockers"
                sx={{
                  width: '100%',
                  marginBottom: 1,
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                required
                label="Risks and Blockers"
                placeholder="Specify if any"
                InputLabelProps={{
                  shrink: true,
                }}
                minRows={4}
                multiline
                onChange={formik.handleChange}
                value={formik.values.risksAndBlockers}
                error={
                  formik.submitCount > 0 &&
                  Boolean(formik.errors.risksAndBlockers)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        variant="outlined"
        sx={{ padding: 3, paddingInlineStart: 5, marginTop: 1 }}
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginBlock: 2 }}
                  gap={2}
                >
                  <Typography color="text.primary" variant="h2">
                    Email thread
                  </Typography>
                </Box>
              </Grid>
              {!isEdit && (
                <>
                  <Grid item xs={6}>
                    <EmailRecipientSearch
                      options={usersListForSearch}
                      handleChange={handleEmailSearchChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      sx={{
                        background: COLORS.HIGHLIGHTS.NEUTRAL,
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '14px',
                        borderRadius: '4px',
                        color: 'GrayText',
                      }}
                      padding="10px"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <CachedIcon />
                      <Typography variant="body2">
                        Please, keep the thread updated on a regular basis
                      </Typography>
                    </Stack>
                  </Grid>
                </>
              )}
              {isEdit && (
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      background: COLORS.HIGHLIGHTS.PROMINENT,
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: '14px',
                      borderRadius: '4px',
                      color: 'GrayText',
                    }}
                    padding="10px"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <LockOutlinedIcon />
                    <Typography variant="body2">
                      The thread has already been sent and email recipients can
                      not be updated from here.
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12}>
                <EmailRecipientList
                  recipientList={formik.values.alertEmailRecipientsEmails}
                  account={account}
                  alert={formik.values}
                  onRemove={removeHandleSelectedEmail}
                  isEdit={isEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        variant="outlined"
        sx={{ padding: 3, paddingInlineStart: 5, marginTop: 1 }}
      >
        <Box
          textAlign="right"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          gap={2}
        >
          <Button
            variant="contained"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            {isEdit ? 'Save updates' : 'Trigger alert'}
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Box>
      </Paper>
    </PageWrapper>
  );
};
