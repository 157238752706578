import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import driveIcon from '../../assets/driveIcon.svg';
import docsIcon from '../../assets/docsIcon.svg';
import fileIcon from '../../assets/file.svg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CustomLinks } from '../../types';
import { useAppDispatch } from '../../redux/hooks';
import { saveLinks } from '../../redux/accounts';

interface Props {
  accountId: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  sharedDriveLink: string | null;
  sowLink?: string | null;
  customLinks: CustomLinks[];
  compensationToolLink?: string | null;
  onModalClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const EditLinksModal: React.FC<Props> = ({
  isModalOpen,
  handleCloseModal,
  sharedDriveLink,
  sowLink,
  customLinks,
  compensationToolLink,
  onModalClick,
  accountId,
}) => {
  const dispatch = useAppDispatch();
  const initialValues = {
    accountId,
    sharedDriveLink: sharedDriveLink ?? '',
    customLinks,
    compensationToolLink: compensationToolLink ?? '',
  };

  const validationSchema = yup.object({
    sharedDriveLink: yup.string().url('Not a valid url.'),
    compensationToolLink: yup.string().url('Not a valid url.'),
    customLinks: yup.array().of(
      yup.object({
        title: yup.string().max(100).required(),
        url: yup.string().url('Invalid URL').max(200).required(),
      }),
    ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(saveLinks(values));
      handleCloseModal();
    },
    validateOnMount: false,
    validateOnChange: false,
  });

  const addCustomLink = useCallback(() => {
    formik.setValues((prevVal) => ({
      ...prevVal,
      customLinks: [
        ...prevVal.customLinks,
        { title: '', url: '', accountId: accountId },
      ],
    }));
    formik.setFieldTouched('customLinks', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleCloseModal}
      open={isModalOpen}
      scroll="body"
      onClick={onModalClick}
    >
      <DialogTitle mb={1}>Edit Linked Resources </DialogTitle>
      <CloseOutlinedIcon
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 22,
          top: 21,
          color: 'grey',
          cursor: 'pointer',
        }}
        onClick={handleCloseModal}
        fontSize="small"
      ></CloseOutlinedIcon>
      <Box
        textAlign="right"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          backgroundColor: '#f7f9fd',
          paddingInlineStart: 3,
          marginInline: 3,
          paddingBlock: 1,
          marginBottom: 3,
        }}
        gap={2}
      >
        <LinkOutlinedIcon />
        <Typography>More Links Coming in V2 </Typography>
      </Box>
      <DialogContent sx={{ maxHeight: 600 }}>
        {/* Drive Link */}
        <TextField
          id="sharedDriveLink"
          name="sharedDriveLink"
          type="text"
          sx={{
            width: '100%',
            marginBottom: 3,
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="Project folder" src={driveIcon} />
              </InputAdornment>
            ),
          }}
          label="Project folder"
          value={formik.values.sharedDriveLink}
          error={Boolean(formik.errors.sharedDriveLink)}
          onChange={formik.handleChange}
        />
        {/* Compensation tool Link */}
        <TextField
          id="compensation-tool"
          name="compensationToolLink"
          type="text"
          sx={{
            width: '100%',
            marginBottom: 3,
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="Compensation tool" src={fileIcon} />
              </InputAdornment>
            ),
          }}
          label="Compensation tool"
          value={formik.values.compensationToolLink}
          error={Boolean(formik.errors.compensationToolLink)}
          onChange={formik.handleChange}
        />
        {/* Active SOW Link */}
        <TextField
          id="active-sow"
          type="text"
          sx={{
            width: '100%',
            marginBottom: 3,
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="Active SOW" src={docsIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
          label="Active SOW"
          value={sowLink}
          disabled
          helperText="Managed by Finance team only"
        />
        {/* Custom Links */}
        {formik.values.customLinks &&
          formik.values.customLinks.map((customLink, index) => (
            <Box key={index}>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ marginBottom: 3 }}
              >
                Custom link
              </Typography>
              <TextField
                id="title"
                type="text"
                sx={{
                  width: '100%',
                  marginBottom: 3,
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                label="Title"
                placeholder="Add link title"
                value={customLink.title}
                InputLabelProps={{
                  shrink: true,
                }}
                name={`customLinks.${index}.title`}
                error={Boolean(
                  formik.errors.customLinks && formik.errors.customLinks[index],
                )}
                onChange={formik.handleChange}
              />
              <TextField
                id="url"
                type="text"
                sx={{
                  width: '100%',
                  marginBottom: 3,
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                label="Url"
                placeholder="Add link title"
                value={customLink.url}
                name={`customLinks.${index}.url`}
                error={Boolean(
                  formik.errors.customLinks && formik.errors.customLinks[index],
                )}
                onChange={formik.handleChange}
              />
            </Box>
          ))}
      </DialogContent>
      <DialogActions
        sx={{ alignItems: 'stretch', justifyContent: 'space-between' }}
      >
        <Button
          onClick={addCustomLink}
          disabled={
            formik.values.customLinks && formik.values.customLinks.length === 10
          }
        >
          Add link
        </Button>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          justifyContent="space-between"
          gap={1}
        >
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            variant="contained"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditLinksModal;
