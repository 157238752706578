const rootKey = '/alert';

export const createAlert = () => {
  return `${rootKey}/create`;
};

export const getAlert = (alertId: string) => {
  return `${rootKey}/${alertId}`;
};

export const getAlerts = () => {
  return `${rootKey}/list`;
};

export const updateAlert = () => {
  return `${rootKey}/update`;
};

export const resolveAlert = () => {
  return `${rootKey}/resolve`;
};
