import React, { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLoading } from '../../redux/accounts';
import { fetchAccount, getSelectedAccount } from '../../redux/account';
import { alertDataSelector, fetchAlert } from '../../redux/alert';

import { PageWrapper } from '../../components';
import { DataState } from '../../types/asyncData';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { paths } from '../../constants/paths';

import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import {
  AlertResolvedStatus,
  CategoryLabels,
  ResolvedStatusLabel,
} from '../../types';
import { formatStringDate } from '../../utils';

import { COLORS } from '../../utils/colors';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { EmailRecipientList } from './components/EmailRecipientList';

export const ViewAlert: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountId, alertId } = useParams();

  const loading = useAppSelector(getLoading);
  const account = useAppSelector(getSelectedAccount);
  const alertLoading = useAppSelector(alertDataSelector.getAlertLoading);
  const alertData = useAppSelector(alertDataSelector.getAlert);

  const getBreadCrumbsPageName = useMemo(
    () => (account ? `Back to ${account.name}` : ``),
    [account],
  );

  useEffect(() => {
    if (accountId) {
      if (alertId) {
        dispatch(fetchAlert(alertId));
      }
      dispatch(
        fetchAccount({
          accountId,
          fetchAllMembers: true,
        }),
      );
    }
  }, [accountId, dispatch, alertId]);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!account || !alertData || loading) {
    return (
      <PageWrapper
        isLoading={loading || alertLoading === DataState.Pending}
      ></PageWrapper>
    );
  }
  return (
    <PageWrapper>
      <>
        <Breadcrumbs
          showPageLink={true}
          breadcrumbs={[]}
          rootPath={`${paths.portfolio}/${accountId}`}
          pageName={getBreadCrumbsPageName}
        />
        <Paper
          variant="outlined"
          sx={{ marginTop: 5, paddingInlineStart: 5, paddingInlineRight: 0 }}
        >
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={7} sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginInlineStart: 2, marginBlock: 2 }}
                  gap={2}
                >
                  <Typography color="text.primary" variant="h2">
                    {`Alert for ${account.name}`}
                  </Typography>
                </Box>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Categories
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBlock: 1,
                      gap: 1,
                    }}
                  >
                    {alertData.categories.map((category) => (
                      <Chip
                        key={category}
                        size="small"
                        variant="filled"
                        label={CategoryLabels[category]}
                        sx={{ marginRight: '8px', borderRadius: '50px' }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2} gap={3} marginBlock={1}>
                <Grid item>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Resolution
                  </Typography>

                  <Chip
                    variant="filled"
                    size="small"
                    label={
                      alertData.resolvedStatus &&
                      ResolvedStatusLabel[alertData.resolvedStatus]
                    }
                    sx={{
                      marginRight: '8px',
                      borderRadius: '50px',
                      marginBlock: 1,
                    }}
                    color={
                      alertData.resolvedStatus === AlertResolvedStatus.NEGATIVE
                        ? 'error'
                        : 'success'
                    }
                  />
                </Grid>

                <Grid item>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Raised on
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {formatStringDate(alertData.createdAt)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    ETA on resolving
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {formatStringDate(alertData.etaForResolve)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Resolved on
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {formatStringDate(alertData.resolvedAt)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Owner
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {alertData.owner?.fullName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginBlock={1}>
                <Grid item xs={12}>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Problem statement
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {alertData.problemStatement}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          variant="outlined"
          sx={{ padding: 3, paddingInlineStart: 5, marginTop: 1 }}
        >
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={7} sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginInlineStart: 2, marginBlock: 2 }}
                  gap={2}
                >
                  <Typography color="text.primary" variant="h2">
                    Key insights and actions
                  </Typography>
                </Box>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      background: COLORS.HIGHLIGHTS.NEUTRAL,
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: '14px',
                      borderRadius: '4px',
                      color: 'GrayText',
                    }}
                    padding="10px"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <EmailOutlinedIcon />
                    <Typography variant="body2">
                      Check the email thread for additional details and
                      discussions
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    What was done
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {alertData.whatWasDone}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Suggested action items
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {alertData.actionPlan}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      color: 'text.secondary',
                    }}
                    variant="h4"
                    component="p"
                  >
                    Risks and blockers
                  </Typography>
                  <Typography
                    textAlign="start"
                    sx={{
                      cursor: 'default',
                      marginBlock: 1,
                    }}
                    variant="h4"
                    component="p"
                  >
                    {alertData.risksAndBlockers}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          variant="outlined"
          sx={{ padding: 3, paddingInlineStart: 5, marginTop: 1 }}
        >
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12} sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Box
                  textAlign="right"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginInlineStart: 2, marginBlock: 2 }}
                  gap={2}
                >
                  <Typography color="text.primary" variant="h2">
                    Initial email thread
                  </Typography>
                </Box>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EmailRecipientList
                    recipientList={alertData.alertEmailRecipientsEmails}
                    account={account}
                    alert={alertData}
                    isEdit={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          variant="outlined"
          sx={{ padding: 3, paddingInlineStart: 5, marginTop: 1 }}
        >
          <Box
            textAlign="right"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
          >
            <Button onClick={onCancel}>Back</Button>
          </Box>
        </Paper>
      </>
    </PageWrapper>
  );
};
