import React, { useMemo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { userDataSelectors } from '../../../redux/userData';

import {
  AccountDetail,
  UpsertAlertType,
  UserWithPermissions,
} from '../../../types';

import { MemberInfoCell } from '../../../components/tableCellRenders';
import { IconButton, Stack, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CloseIcon from '@mui/icons-material/Close';

interface EmailRecipientProps {
  recipientList: string[];
  account: AccountDetail;
  alert: UpsertAlertType;
  onRemove?: (email?: string) => void;
  isEdit?: boolean;
}
export const EmailRecipientList = ({
  recipientList,
  account,
  alert,
  onRemove,
  isEdit,
}: EmailRecipientProps) => {
  const usersList = useAppSelector(userDataSelectors.getAllUsersList);

  const author = useMemo(() => {
    return usersList.filter((user) => user.id === alert.authorId)[0];
  }, [alert.authorId, usersList]);

  const emailRecipientList = useMemo(() => {
    const filteredEmailList = recipientList.filter(
      (recipient) =>
        ![
          account?.deliveryManager?.email,
          account?.portfolioDeliveryManager?.email,
          alert.owner?.email,
          author?.email,
        ].includes(recipient),
    );
    return usersList.filter((user) => filteredEmailList.includes(user.email));
  }, [recipientList, alert.owner, account, author, usersList]);

  const hrEmail = [
    {
      email: 'alerts@agileengine.com',
      designation:
        'Key stakeholders is that needs to be informed of any escalation',
    },
    {
      email: 'hr_leads@agileengine.com',
      designation: 'HR team leads to be in the for potential involvement',
    },
  ];

  return (
    <>
      {/* Portfolio Delivery Manager */}
      {account.portfolioDeliveryManager?.email &&
        recipientList.includes(account.portfolioDeliveryManager.email) && (
          <EmailRecipientComponent
            user={account.portfolioDeliveryManager}
            userRole={`Portfolio Delivery Manager ${
              account.portfolioDeliveryManager?.email ===
              account.deliveryManager?.email
                ? '/ Delivery Manager'
                : ''
            }
            ${
              account.portfolioDeliveryManager?.email === alert.owner?.email
                ? '/ Alert owner'
                : ''
            }
            ${
              account.portfolioDeliveryManager?.email === author?.email
                ? '/ Alert author'
                : ''
            }`}
          />
        )}

      {/* Delivery Manager */}
      {account.deliveryManager?.email &&
        recipientList.includes(account.deliveryManager.email) &&
        account.deliveryManager?.email !==
          account.portfolioDeliveryManager?.email && (
          <EmailRecipientComponent
            user={account.deliveryManager}
            userRole={`Delivery Manager
            ${
              account.deliveryManager?.email === alert.owner?.email
                ? '/ Alert owner'
                : ''
            }
            ${
              account.deliveryManager?.email === author?.email
                ? '/ Alert author'
                : ''
            }`}
          />
        )}

      {/* Alert Author */}
      {author &&
        recipientList.includes(author.email) &&
        ![
          account.portfolioDeliveryManager?.email,
          account.deliveryManager?.email,
        ].includes(author?.email) && (
          <EmailRecipientComponent
            user={author}
            userRole={`Alert author
        ${author?.email === alert.owner?.email ? '/ Alert owner' : ''}`}
          />
        )}

      {/* Alert Owner */}
      {alert.owner &&
        recipientList.includes(alert.owner.email) &&
        ![
          account.portfolioDeliveryManager?.email,
          account.deliveryManager?.email,
          author?.email,
        ].includes(alert.owner?.email) && (
          <EmailRecipientComponent
            user={alert.owner}
            userRole={'Alert owner'}
          />
        )}

      {/* Additional members */}
      {emailRecipientList.map((recipient) => (
        <EmailRecipientComponent
          key={recipient.email}
          user={recipient}
          userRole={null}
          onRemove={!isEdit ? onRemove : undefined}
        />
      ))}
      {/* Auto CC required members */}
      {hrEmail.map((recipient) => (
        <EmailRecipientComponent
          key={recipient.email}
          user={recipient}
          userRole={'Auto CC required'}
        />
      ))}
    </>
  );
};

export interface EmailRecipientComponentProps {
  user?: Partial<UserWithPermissions>;
  userRole: string | null;
  onRemove?: (email?: string) => void;
}

const EmailRecipientComponent = ({
  user,
  userRole,
  onRemove,
}: EmailRecipientComponentProps) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{
        marginBlock: 1,
        paddingBlock: 1,
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      }}
    >
      <MemberInfoCell
        hideAvatar={!user?.avatarUrl}
        userInfo={{
          avatarUrl: user?.avatarUrl,
          fullName: user?.fullName ?? user?.email ?? '',
          stream: user?.designation,
        }}
      />
      {userRole && (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: 'GrayText',
          }}
        >
          {userRole === 'Auto CC required' && <MailOutlineIcon />}
          {userRole}
        </Typography>
      )}
      {!userRole && !!onRemove && (
        <IconButton
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: 'GrayText',
            cursor: 'pointer',
          }}
          onClick={() => onRemove(user?.email)}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};
