import { accountByIdPath } from './account';
import {
  accounts,
  accountMargins,
  fetchAccounts,
  accountMarginReport,
  accountsStatus,
  saveLink,
} from './accounts';
import {
  projectAndMembers,
  projectMargins,
  projectStatus,
  projectTimetracking,
} from './projects';
import { authLogin, authCustomLogin } from './auth';
import { contractor, locations, contractorsByZoho } from './contractor';
import {
  departments,
  financeDepartmentId,
  rootDepartments,
} from './departments';
import {
  kpiAssignments,
  allKpiAssignmentsForUser,
  sharedResultsForAssignment,
  bonusPlanUpdateSnippet,
} from './kpiAssignments';
import {
  kpiDefinition,
  kpiDefinitionId,
  kpiDefinitionOwners,
  kpiDefinitionCategories,
} from './kpiDefinition';
import { kpiMetrics, kpiOwners, kpiMetricsByZoho } from './kpiMetrics';
import {
  adjustKpis,
  approveKpis,
  checkApproved,
  closeCurrentPeriod,
  submissions,
  submitKpis,
  calculateKpiBonus,
} from './kpiValueSubmission';
import { periods, currentPeriod } from './periods';
import { reports } from './reports';
import { reportIssue, reportIncorrectReport } from './reportIssue';
import { myMetrics } from './myMetrics';
import {
  usersList,
  allUsersList,
  userProfile,
  userAccountsCount,
  fetchCompensationRange,
} from './users';
import {
  fetchUserBonusPlan,
  updateUserBonusPlan,
  getBonusesByUserId,
} from './bonuses';
import { statusReport } from './statusReport';
import { assessmentCreate } from './assessment';
import { clientInvoiceGenerate } from './clientInvoice';
import {
  createAlert,
  getAlert,
  updateAlert,
  getAlerts,
  resolveAlert,
} from './alert';
import { adminReports, sharedMetricsInconsistencies } from './admin';

export const apiEndpoints = {
  accountByIdPath,
  accountMargins,
  accountsStatus,
  accounts,
  assessmentCreate,
  fetchAccounts,
  saveLink,
  projectMargins,
  projectStatus,
  projectTimetracking,
  adjustKpis,
  approveKpis,
  authCustomLogin,
  authLogin,
  checkApproved,
  closeCurrentPeriod,
  contractorsByZoho,
  contractor,
  currentPeriod,
  departments,
  rootDepartments,
  financeDepartmentId,
  kpiAssignments,
  allKpiAssignmentsForUser,
  sharedResultsForAssignment,
  kpiDefinition,
  kpiDefinitionId,
  kpiDefinitionOwners,
  kpiDefinitionCategories,
  kpiMetrics,
  kpiOwners,
  kpiMetricsByZoho,
  locations,
  periods,
  reports,
  adminReports,
  reportIssue,
  submissions,
  submitKpis,
  calculateKpiBonus,
  myMetrics,
  userProfile,
  usersList,
  userAccountsCount,
  accountMarginReport,
  fetchCompensationRange,
  statusReport,
  fetchUserBonusPlan,
  updateUserBonusPlan,
  getBonusesByUserId,
  bonusPlanUpdateSnippet,
  reportIncorrectReport,
  clientInvoiceGenerate,
  projectAndMembers,
  createAlert,
  getAlert,
  updateAlert,
  allUsersList,
  getAlerts,
  resolveAlert,
  sharedMetricsInconsistencies,
};
