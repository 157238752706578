import React, { useCallback, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import lookerStudio from '../../../../assets/lookerStudio.svg';
import driveIcon from '../../../../assets/driveIcon.svg';
import docsIcon from '../../../../assets/docsIcon.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { appConfig, LOOKER_REPORT } from '../../../../constants';
import EditLinksModal from '../../../editLinksModal/EditLinksModal';
import { CustomLinks } from '../../../../types';
import fileIcon from '../../../../assets/file.svg';
import agileEngineIcon from '../../../../assets/agileEngine.svg';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { is } from '../../../../utils';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import { paths } from '../../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/hooks';
import { alertSlice } from '../../../../redux/alert';

interface Props {
  accountName: string;
  sharedDriveLink: string | null;
  sowLink?: string | null;
  customLinks: CustomLinks[] | [];
  compensationToolLink?: string | null;
  accountId: string;
}

export function AccountMenuDropDown({
  accountName,
  sharedDriveLink,
  sowLink,
  customLinks,
  compensationToolLink,
  accountId,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [accountMenuVisible, setAccountMenuVisible] =
    useState<null | HTMLElement>(null);

  const open = Boolean(accountMenuVisible);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = useCallback(
    (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (event) event?.stopPropagation();
      setIsModalOpen(!isModalOpen);
    },
    [isModalOpen, setIsModalOpen],
  );

  const handleAccountMenu = useCallback(
    (AccountElement: HTMLElement | null) => {
      setAccountMenuVisible(AccountElement);
    },
    [],
  );

  const onAddAlert = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event?.stopPropagation();
      dispatch(alertSlice.actions.resetAlert());
      navigate(`/${accountId}/${paths.alert}/create`);
    },
    [navigate, accountId, dispatch],
  );

  const navigateToCreateNewPositionLp = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event?.stopPropagation();

      let lpUrl;
      if (is.dev || is.localhost) {
        lpUrl = appConfig.lpDevUrl;
      } else if (is.stage || is.preprod) {
        lpUrl = appConfig.lpStageUrl;
      } else {
        lpUrl = appConfig.lpProdUrl;
      }

      window.open(
        `${lpUrl}/job-openings/create`,
        '_blank',
        'noopener,noreferrer',
      );
    },
    [],
  );

  const navigateToProjectLink = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (sharedDriveLink) {
        event?.stopPropagation();
        window.open(sharedDriveLink, '_blank', 'noopener,noreferrer');
      }
    },
    [sharedDriveLink],
  );

  const navigateToSOWLink = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (sowLink) {
        event?.stopPropagation();
        window.open(sowLink, '_blank', 'noopener,noreferrer');
      }
    },
    [sowLink],
  );

  const navigateToLPdashboard = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event?.stopPropagation();

      let lpUrl;
      if (is.dev || is.localhost) {
        lpUrl = appConfig.lpDevUrl;
      } else if (is.stage || is.preprod) {
        lpUrl = appConfig.lpStageUrl;
      } else {
        lpUrl = appConfig.lpProdUrl;
      }

      window.open(
        `${lpUrl}/job-openings?client=${accountName}`,
        '_blank',
        'noopener,noreferrer',
      );
    },
    [accountName],
  );

  const navigateToCustomlLink = useCallback(
    (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      customLink: string,
    ) => {
      event?.stopPropagation();
      window.open(customLink, '_blank', 'noopener,noreferrer');
    },
    [],
  );

  const navigateToCompensationToolLink = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (compensationToolLink) {
        event?.stopPropagation();
        window.open(compensationToolLink, '_blank', 'noopener,noreferrer');
      }
    },
    [compensationToolLink],
  );

  const navigateToLookerReport = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event?.stopPropagation();
      const params = {
        'ds0.account': accountName,
      };
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString);
      window.open(
        LOOKER_REPORT + `?params=${encodedParams}`,
        '_blank',
        'noopener,noreferrer',
      );
    },
    [accountName],
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      handleAccountMenu(null);
    },
    [handleAccountMenu],
  );

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      handleAccountMenu(event.currentTarget);
    },
    [handleAccountMenu],
  );

  const onModalClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event?.stopPropagation();
    },
    [],
  );

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={accountMenuVisible} open={open} onClose={handleClose}>
        <Typography variant="body2" sx={{ marginInlineStart: 2, marginTop: 2 }}>
          Actions
        </Typography>
        <MenuItem onClick={onAddAlert}>
          <AddAlertOutlinedIcon color="secondary" />
          <Typography variant="body1" sx={{ marginInlineStart: 1 }}>
            Add alert
          </Typography>
        </MenuItem>
        <MenuItem onClick={navigateToCreateNewPositionLp}>
          <PersonAddOutlinedIcon color="secondary" />
          <Typography variant="body1" sx={{ marginInlineStart: 1 }}>
            Open new position
          </Typography>
        </MenuItem>

        <Typography variant="body2" sx={{ marginInlineStart: 2, marginTop: 1 }}>
          Links
        </Typography>
        {sharedDriveLink && (
          <MenuItem onClick={navigateToProjectLink}>
            <img alt="Project folder" src={driveIcon} />
            <Typography variant="body1" sx={{ marginInlineStart: 1.25 }}>
              Project folder ↗
            </Typography>
          </MenuItem>
        )}
        {sowLink && (
          <MenuItem onClick={navigateToSOWLink}>
            <img alt="SOW" src={docsIcon} />
            <Typography variant="body1" sx={{ marginInlineStart: 1.25 }}>
              SOW ↗
            </Typography>
          </MenuItem>
        )}
        {compensationToolLink && (
          <MenuItem onClick={navigateToCompensationToolLink}>
            <img alt="Compensation Link" src={fileIcon} />
            <Typography variant="body1" sx={{ marginInlineStart: 1.25 }}>
              Compensation tool ↗
            </Typography>
          </MenuItem>
        )}
        {/* Looker Report */}
        <MenuItem
          onClick={navigateToLookerReport}
          sx={{ marginInlineStart: 0.5 }}
        >
          <img alt="CSAT report" src={lookerStudio} />
          <Typography variant="body1" sx={{ marginInlineStart: 1.7 }}>
            CSAT report ↗
          </Typography>
        </MenuItem>
        <MenuItem onClick={navigateToLPdashboard}>
          <img alt="LP Dashboard" src={agileEngineIcon} />
          <Typography variant="body1" sx={{ marginInlineStart: 1.25 }}>
            Hiring dashboard ↗
          </Typography>
        </MenuItem>
        {/* Custom Links */}
        {customLinks?.length > 0 &&
          customLinks.map((customLink) => (
            <MenuItem
              onClick={(e) => navigateToCustomlLink(e, customLink.url)}
              key={customLink.title}
            >
              <LinkOutlinedIcon />
              <Typography variant="body1" sx={{ marginInlineStart: 1.25 }}>
                {customLink.title} ↗
              </Typography>
            </MenuItem>
          ))}

        <Typography variant="body2" sx={{ marginInlineStart: 2, marginTop: 2 }}>
          Settings
        </Typography>
        <MenuItem onClick={handleToggleModal} sx={{ marginInlineStart: 0.5 }}>
          <EditOutlinedIcon color="secondary" />
          <Typography variant="body1" sx={{ marginInlineStart: 1.7 }}>
            Edit links
          </Typography>
        </MenuItem>
      </Menu>
      {isModalOpen && (
        <EditLinksModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleToggleModal}
          sharedDriveLink={sharedDriveLink}
          sowLink={sowLink}
          customLinks={customLinks}
          compensationToolLink={compensationToolLink}
          onModalClick={onModalClick}
          accountId={accountId}
        />
      )}
    </>
  );
}
