import * as yup from 'yup';
import { KPI_SCOPES, KPI_TYPES } from '../../constants';
import { KpiMetricType } from '../../types/kpiMetric';

export const validationSchema = yup
  .object({
    kpiDefinitionId: yup.string().required(),
    kpiDefinition: yup.object({
      scope: yup.string(),
    }),
    kpiOwnerId: yup.string().when('kpiDefinition', {
      is: (kpiDefinition: KpiMetricType) =>
        kpiDefinition.scope === KPI_SCOPES.INDIVIDUAL,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    bonusShare: yup.lazy((val) =>
      Array.isArray(val)
        ? yup.array().of(
            yup.object({
              bonusShare: yup
                .number()
                .min(0, 'Should be greater or equal to 0')
                .max(99999, '99999 max')
                .required(),
              period: yup.number().required(),
            }),
          )
        : yup
            .number()
            .min(0, 'Should be greater or equal to 0')
            .max(99999, '99999 max')
            .required(),
    ),
    bestCase: yup
      .number()
      .min(0)
      .label('Best case')
      .when('kpiDefinition', {
        is: (kpiDefinition: KpiMetricType) =>
          kpiDefinition.type === KPI_TYPES.FIXED_ABSOLUTE ||
          kpiDefinition.type === KPI_TYPES.FIXED_PERCENTAGE,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) =>
          schema.required('Required').notOneOf([yup.ref('worstCase')], ''),
      }),
    worstCase: yup
      .number()
      .min(0)
      .label('Worst case')
      .when('kpiDefinition', {
        is: (kpiDefinition: KpiMetricType) =>
          kpiDefinition.type === KPI_TYPES.FIXED_ABSOLUTE ||
          kpiDefinition.type === KPI_TYPES.FIXED_PERCENTAGE,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) =>
          schema.required('Required').notOneOf([yup.ref('bestCase')], ''),
      }),
    startPeriod: yup.number().required(),
    endPeriod: yup.number().nullable(),
    effectiveFrom: yup
      .number()
      .notRequired()
      .test(
        'effectiveFrom',
        'effectiveFrom is not within startperiod endperiod range',
        function (value) {
          if (!value) return true;

          const startPeriod = this.parent.startPeriod;
          const gteStartPeriod =
            !startPeriod || !!(startPeriod && value >= startPeriod);

          const endPeriod = this.parent.endPeriod;
          const lteEndPeriod =
            !endPeriod || !!(endPeriod && value <= endPeriod);

          return gteStartPeriod && lteEndPeriod;
        },
      ),
    monthWiseShare: yup.boolean().notRequired(),
  })
  .strict();
